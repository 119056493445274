import React from 'react';
import type { FC } from 'react';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { Container } from './ErrorPage.style';
import { getWindow } from '@app/shared/utils/window';

type Props = {
  buttonLabel?: string;
};

export const ErrorPage: FC<Props> = ({ buttonLabel = '' }) => {
  const isWebView = useIsWebView();
  const deeplink = 'pedidosya://launcher';
  const webUrl = '/';

  const onBack = () => {
    const wn = getWindow();
    const finalUrl = isWebView ? deeplink : webUrl;
    wn.location.href = finalUrl;
  };

  const retry = () => {
    const wn = getWindow();
    wn.location.reload();
  };

  return (
    <Container>
      <Error
        errorCode={500}
        primaryActionLabel={'Reintentar'}
        primaryActionClick={retry}
        secondaryActionLabel="Ir al Inicio"
        secondaryActionClick={onBack}
      />
    </Container>
  );
};
