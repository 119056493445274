import { defineMessages } from 'react-intl';

export default defineMessages({
  'reason.addItemsDisclaimer': {
    id: 'reason.addItemsDisclaimer',
    defaultMessage: 'No cargu\u00e9 el cup\u00f3n.',
  },
  'reason.addItemsButtonDisclaimer': {
    id: 'reason.addItemsButtonDisclaimer',
    defaultMessage: 'Mi pedido aparece duplicado.',
  },
  'reason.changeAddressDisclaimer': {
    id: 'reason.changeAddressDisclaimer',
    defaultMessage: 'Se demor\u00f3 m\u00e1s de lo que esperaba.',
  },
  'reason.changeAddressButtonDisclaimer': {
    id: 'reason.changeAddressButtonDisclaimer',
    defaultMessage: 'Agregué productos que no quiero.',
  },
  'reason.regretOrderDisclaimer': {
    id: 'reason.regretOrderDisclaimer',
    defaultMessage: '¿Nos cuentas por qué?',
  },
});
