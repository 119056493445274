import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getItem, setItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/constants';
import type { FC } from 'react';
import styled from 'styled-components';
import Switch from '@pedidosya/web-fenix/atoms/Switch';

const MockContainer = styled.div`
  display: flex;
  padding-right: 16px;
  & > span:first-child {
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const getInitialValue = () => {
  try {
    const value = getItem(Constants.HANDLE_MOCK, 'false');
    return value && value === 'false' ? false : true || true;
  } catch (e) {
    return false;
  }
};

export const MockSwitch: FC = () => {
  const { go } = useHistory();
  const [state, setState] = useState<boolean>(getInitialValue());

  const onChange = (state: boolean) => {
    setState(state);
    setItem(Constants.HANDLE_MOCK, state.toString());
    go(0);
  };

  return (
    <MockContainer>
      <span>Mock</span>
      <Switch selected={state} onClick={onChange} />
    </MockContainer>
  );
};
