import React, { useContext, useEffect, useState, createContext } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { Constants } from '@app/shared/config/constants';
import { handleItem } from '@app/shared/utils/storage';
import { Urls } from '@app/shared/config/urls';
import { ErrorBoundary } from '@app/shared/components/ErrorBoundary';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { Env } from '@app/shared/models/Env';
import Http from '@app/shared/utils/http';

type Props = {
  context?: Env;
  getPublicEnv: typeof getPublicEnv;
} & PropsWithChildren;

const PublicEnvContext = createContext<Partial<Env>>({});

export const getPublicEnv = async () => {
  const { data } = await Http.get<Env>(`${Urls.ENV}`);
  handleItem(Constants.HANDLE_MOCK, data.mock === true ? 'false' : 'false');
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider: FC<Props> = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.HANDLE_MOCK, publicEnv.mock === true ? 'false' : 'false');
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) return <ErrorBoundary error={error} />;

  if (!publicEnv) return <PeyaLoader position="center" />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};
