import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  'askUser.extra.wallet.message': {
    id: 'askUser.extra.wallet.message',
    defaultMessage: 'Saldo en PedidosYa.',
  },
  'askUser.extra.cuopon.message': {
    id: 'askUser.extra.cuopon.message',
    defaultMessage: 'Lo encontrarás en Mis cupones.',
  },
  'askUser.extra.list_option.also': {
    id: 'askUser.extra.list_option.also',
    defaultMessage: 'Tambien te devolveremos',
  },
  'askUser.main.list_option.refund_inmmediate': {
    id: 'askUser.main.list_option.refund_inmmediate',
    defaultMessage: 'Lo recibes ahora',
  },
  'askUser.main.list_option.wallet.description': {
    id: 'askUser.main.list_option.wallet.description',
    defaultMessage: 'Lo verás disponible al momento de pagar tus próximos pedidos.',
  },
  'askUser.main.list_option.wallet.title': {
    id: 'askUser.main.list_option.wallet.title',
    defaultMessage: 'Saldo de PedidosYa',
  },
  'askUser.main.list_option.card.description': {
    id: 'askUser.main.list_option.card.description',
    defaultMessage: 'Tu banco o emisor puede tardar hasta 7 días hábiles en acreditar tu dinero.',
  },
  'askUser.main.list_option.card.title': {
    id: 'askUser.main.list_option.card.title',
    defaultMessage: 'En tu {value}',
  },
  'askUser.main.list_option.voucher.description': {
    id: 'askUser.main.list_option.voucher.description',
    defaultMessage: 'Lo verás disponible al momento de pagar tus próximos pedidos.',
  },
  'askUser.main.list_option.voucher.title': {
    id: 'askUser.main.list_option.voucher.title',
    defaultMessage: 'Cupón de reembolso',
  },
});
