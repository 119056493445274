import { CancellationRepository } from '@app/cancellation/data/repositories';
import {
  CancelQuoteRequest,
  DetailRequest,
  QuoteRequest,
  ReasonsRequest,
  ReasonsResponse,
  QuoteContextResponse,
  CancelQuoteResponse,
  DetailResponse,
} from '@app/cancellation/domain/models';
import { CancellationService as ICancellationService } from '@app/cancellation/domain/services';

export class CancellationService implements ICancellationService {
  private repository: CancellationRepository;
  constructor() {
    this.repository = new CancellationRepository();
  }

  private getCityFromUrl(url: string) {
    if (!url) {
      return '';
    }
    const regex = new RegExp('/([A-Za-z]+)/([a-zA-Z]+)/([a-zA-z0-9-]+)$', 'gi');
    if (regex.test(url)) {
      return url.split('/')[2];
    }

    return '';
  }

  async generateQuote(payload: QuoteRequest): Promise<QuoteContextResponse> {
    const result = await this.repository.generateQuote(payload);
    const extra = result.extraParams;
    return {
      quoteId: result.quoteId,
      code: extra?.orderCode,
      city: this.getCityFromUrl(extra?.vendorInformation?.url),
      countryCode: result?.countryCode ? result?.countryCode.toLowerCase() : '',
      userMail: extra.userMail || '',
      businessType: extra.businessType || '',
      totalAmount: extra.totalAmount || 0,
      currencySymbol: extra.currencySymbol || '',
      orderStatusState: extra.orderStatusState || '',
      vendorInformation: extra.vendorInformation || {},
      paymentFlow: extra?.paymentFlow,
      refundInformation: extra?.refundInformation,
      orderRegrettedReason: extra?.selectedReason?.globalReason as string,
      orderRejectionInternalReason: extra?.selectedReason?.internalReason as string,
      user: extra?.user,
      config: result?.config,
    };
  }

  getReasons(payload: ReasonsRequest): Promise<ReasonsResponse> {
    return this.repository.getReasons(payload);
  }

  cancelQuote(payload: CancelQuoteRequest): Promise<CancelQuoteResponse> {
    return this.repository.cancelQuote(payload);
  }
  getDetail(payload: DetailRequest): Promise<DetailResponse> {
    return this.repository.getDetail(payload);
  }
}
