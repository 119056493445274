import React, { memo } from 'react';
import { TopBar } from '@app/shared/components/TopBar';
import { Container } from '@app/shared/components/Container';
import { InternalContainer } from './LayoutWeb.style';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  canvanColor?: 'gray' | 'green' | string;
  iconType?: 'close' | 'back' | string;
  paddingBottom?: number;
  showMock?: boolean;
  showCanvan?: boolean;
  onBack?(): void;
}

export const LayoutWeb: FC<Props> = memo(function LayoutWeb({
  children,
  onBack = () => {},
  showMock = true,
  canvanColor = 'gray',
  iconType = 'close',
  showCanvan = true,
  paddingBottom = 0,
  ...props
}) {
  return (
    <main {...props}>
      <TopBar
        callback={onBack}
        showMock={showMock}
        showCanvan={true}
        canvanColor={canvanColor}
        iconType={iconType}
      />
      <Container pTop="100" pBottom="0" style={{ textAlign: 'center' }}>
        <InternalContainer>{children}</InternalContainer>
      </Container>
    </main>
  );
});
