import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { Verticals } from '@app/shared/config/verticals';
import { Constants } from '@app/shared/config/constants';
import { LocalReasons } from '@app/shared/config/localReasons';

export const rule = {
  name: 'feedback-remedies',
  rules: [
    {
      description: 'DELIVERY_ETA_TOO_LONG + RESTAURANT',
      when: { closure: 'checkOption', reason: 'DELIVERY_ETA_TOO_LONG', vertical: 'RESTAURANT' },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherRestaurants',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG + All',
      when: {
        closure: 'checkOption',
        reason: 'DELIVERY_ETA_TOO_LONG',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherStore',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'UPDATE_ORDER_ADDRESS + RESTAURANT',
      when: { closure: 'checkOption', reason: 'UPDATE_ORDER_ADDRESS', vertical: 'RESTAURANT' },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithDirection',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'UPDATE_ORDER_ADDRESS + ALL',
      when: {
        closure: 'checkOption',
        reason: 'UPDATE_ORDER_ADDRESS',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithDirection',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          params: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'UPDATE_ORDER_PAYMENT_METHOD + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'UPDATE_ORDER_PAYMENT_METHOD',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderOtherPayment',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'UPDATE_ORDER_PAYMENT_METHOD + ALL',
      when: {
        closure: 'checkOption',
        reason: 'UPDATE_ORDER_PAYMENT_METHOD',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderOtherPayment',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'ADD_DELETE_PRODUCT + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'ADD_DELETE_PRODUCT',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'ADD_DELETE_PRODUCT + ALL',
      when: {
        closure: 'checkOption',
        reason: 'ADD_DELETE_PRODUCT',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'COUPON_NOT_LOADED + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'COUPON_NOT_LOADED',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithCupon',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'COUPON_NOT_LOADED + ALL',
      when: {
        closure: 'checkOption',
        reason: 'COUPON_NOT_LOADED',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithCupon',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'MISTAKE_ERROR + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'MISTAKE_ERROR',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherRestaurants',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'MISTAKE_ERROR + ALL',
      when: {
        closure: 'checkOption',
        reason: 'MISTAKE_ERROR',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherStore',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'ORDER_DUPLICATE + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'ORDER_DUPLICATE',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherRestaurants',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'ORDER_DUPLICATE + ALL',
      when: {
        closure: 'checkOption',
        reason: 'ORDER_DUPLICATE',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherStore',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'ORDER_REGRETTED + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'ORDER_REGRETTED',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherRestaurants',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'ORDER_REGRETTED + ALL',
      when: {
        closure: 'checkOption',
        reason: 'ORDER_REGRETTED',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.findOtherOptions',
        firstButton: 'remedies.seeOtherStore',
        secondButton: 'remedies.goToHome',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
        secondButtonAction: {
          type: Constants.WEBEVENT,
          event: WebViewEvents.HOME,
          trackEvent: Constants.FEEDBACK_ACTION_HOME,
        },
      },
    },
    {
      description: 'FORGET_ADD_PRODUCTS + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'FORGET_ADD_PRODUCTS',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'FORGET_ADD_PRODUCTS + ALL',
      when: {
        closure: 'checkOption',
        reason: 'FORGET_ADD_PRODUCTS',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'DONT_WANT_PRODUCTS + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: 'DONT_WANT_PRODUCTS',
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'DONT_WANT_PRODUCTS + ALL',
      when: {
        closure: 'checkOption',
        reason: 'DONT_WANT_PRODUCTS',
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderUpdateProducts',
        firstButton: 'remedies.repeatOrder',
        secondButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
        secondButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'ITEM_UNAVAILABLE + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.ITEM_UNAVAILABLE,
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.item_unavailable.restaurant',
        firstButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'ITEM_UNAVAILABLE + ALL',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.ITEM_UNAVAILABLE,
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.item_unavailable.store',
        firstButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
    {
      description: 'UNAVAILABLE_TO_FIND + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.UNAVAILABLE_TO_FIND,
        vertical: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithDirection',
        firstButton: 'remedies.repeatOrder',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
      },
    },
    {
      description: 'UNAVAILABLE_TO_FIND + ALL',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.UNAVAILABLE_TO_FIND,
        vertical: '*',
        except: ['RESTAURANT'],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'remedies.repeatOrderWithDirection',
        firstButton: 'remedies.repeatOrder',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.SAME_STORE,
          param: 'orderId',
          trackEvent: Constants.FEEDBACK_ACTION_REPEAT_ORDER,
        },
      },
    },
    {
      description: 'NEVER_DELIVERED + RESTAURANT',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.NEVER_DELIVERED,
        vertical: Verticals.RESTAURANT,
      },
      then: {
        closure: 'getTranslation',
        mainText: 'detail.badExperiencie',
        secondaryText: 'remedies.findOtherOptions',
        firstButton: 'remedies.orderOtherRestaurant',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_EVENT,
          param: 'vertical',
          value: Verticals.RESTAURANT.toLowerCase(),
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_RESTAURANT,
        },
      },
    },
    {
      description: 'NEVER_DELIVERED + ALL',
      when: {
        closure: 'checkOption',
        reason: LocalReasons.NEVER_DELIVERED,
        vertical: '*',
        except: [Verticals.RESTAURANT],
      },
      then: {
        closure: 'getTranslation',
        mainText: 'detail.badExperiencie',
        secondaryText: 'remedies.findOtherOptions',
        firstButton: 'remedies.orderOtherStore',
        primaryButtonAction: {
          type: Constants.DEEPLINK,
          event: Constants.OTHER_STORE_QC_EVENT,
          param: 'vertical',
          value: '',
          trackEvent: Constants.FEEDBACK_ACTION_OTHER_STORE,
        },
      },
    },
  ],
};
