import React from 'react';
import { PaymentMethods } from '@app/shared/config/paymentMethods';
import Coupon from '@pedidosya/web-fenix/icons/Coupon';
import PedidosYa from '@pedidosya/web-fenix/icons/PedidosYaPay';

export const AskUserExtraRule = {
  name: 'ask_user_extra_refubnd',
  rules: [
    {
      description: 'wallet',
      when: {
        closure: 'checkExtraRefundMethod',
        refundMethod: PaymentMethods.WALLET,
      },
      then: {
        closure: 'getTranslationRefundMethod',
        message: 'askUser.extra.wallet.message',
        icon: <PedidosYa />,
        color: '#2656F7',
      },
    },
    {
      description: 'cuopon',
      when: {
        closure: 'checkExtraRefundMethod',
        refundMethod: PaymentMethods.COUPON,
      },
      then: {
        closure: 'getTranslationRefundMethod',
        message: 'askUser.extra.cuopon.message',
        icon: <Coupon />,
        color: '#100423',
      },
    },
  ],
};
