import React from 'react';
import Translations from './ErrorBoundary.translation';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import { ErrorPage } from '@app/shared/components/ErrorPage';
import { sentryHub } from '@app/shared/utils/sentry';
import type { FC } from 'react';

type Props = {
  error?: any;
  [key: string]: any;
};
export const ErrorBoundary: FC<Props> = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <FenixProvider>
      <>
        <ErrorPage buttonLabel={Translations.home.defaultMessage} />
      </>
    </FenixProvider>
  );
};
