import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Shadow = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const Shape: FC<PropsWithChildren> = ({ children }) => {
  return <Shadow data-testid="shape">{children}</Shadow>;
};
