import { PubSubEvents } from '@app/cancellation/infra/config/pubSubEvents';
import { HelpController, ControllerDependencies } from '@app/cancellation/domain/controllers/Help';
import { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import isEmpty from 'lodash/isEmpty';

export const useHelp: HelpController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { state } = dpn.useCancellationContext();
  const Pub = dpn.usePub();
  dpn.useHelpEvents({ dependencies: dependencies as Depedencies });

  /**
   * goToHelp
   *
   * @description Function to generate link or DeepLink a redirect to help center
   * @param {Event} e - Event
   */
  const goToHelp = (e: Event) => {
    if (e instanceof Event) {
      e.preventDefault();
    }
    Pub(PubSubEvents.ON_GO_HELP_CENTER, {});
  };

  /**
   * goToOrder
   *
   * @description Function to close the webview and stay in order status
   * @param {Event} e - Event
   */
  const goToOrder = (e: Event) => {
    if (e instanceof Event) {
      e.preventDefault();
    }
    Pub(PubSubEvents.ON_GO_BACK_HELP_CENTER, {});
  };

  return {
    goToOrder,
    goToHelp,
    hasReturn: !isEmpty(state?.orderDetail?.orderId),
  };
};
