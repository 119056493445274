import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const IconContainer = styled.div`
  background-color: #fff;
  border: 1px solid #dbdade;
  border-radius: 4px;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    fill: ${(props) => (props.color ? props.color : '#100423')};
  }
`;

const Amount = styled(Typography)`
  margin: 0;
  padding: 0;
  text-align: left;
`;

const Label = styled(Typography)`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export { Container, IconContainer, Amount, Label };
