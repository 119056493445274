import { useEffect, useState } from 'react';
import { AskUserExtraRules, AskUserMainRules } from '@app/cancellation/use_cases/utils/engine';
import {
  AskUserListOptionController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/AskUserListOption';
import { ExtraRefundMethod, AvailableRefundMethod } from '@shared/models/Quote';
import {
  AskUser,
  AskUserRule,
  AskUserExtra,
  AskUserExtraRule,
} from '@app/cancellation/domain/models';
import isArray from 'lodash/isArray';

export const useAskUserListOption: AskUserListOptionController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { state, dispatch } = dpn.useCancellationContext();
  const [extraRefund, setExtraRefund] = useState<Array<AskUserExtra>>([]);
  const [mainRefund, setMianRefund] = useState<Array<AskUserRule>>([]);

  /**
   * useEffect to calculate extra refund method
   * using engine that return text and icon to use
   */
  useEffect(() => {
    const getOptions = async (option: ExtraRefundMethod): Promise<AskUserExtra> => {
      const result = (await AskUserExtraRules({ refundMethod: option?.code })) as AskUserExtraRule;

      return { ...result, amount: option?.amount };
    };

    const setOptions = async (options: Array<ExtraRefundMethod>) => {
      const promises = options.map((element) => getOptions(element));
      const values = await Promise.all(promises);
      if (isArray(values)) {
        setExtraRefund(values.filter((element) => element?.message));
      }
    };

    if (state?.orderDetail?.refundInformation?.extrarefundMethods) {
      setOptions(state?.orderDetail?.refundInformation?.extrarefundMethods);
    }
  }, [state?.orderDetail?.refundInformation?.extrarefundMethods]);

  /**
   * useEffect to calculate main refund method
   * using engine that return title and description
   */
  useEffect(() => {
    const getOptions = async (option: AvailableRefundMethod): Promise<AskUser> => {
      const result = (await AskUserMainRules({ refundMethod: option?.identifier })) as AskUserRule;
      return {
        ...result,
        id: option?.identifier,
        value: option?.description,
        selected: option?.selected,
      };
    };

    const setOptions = async (options: Array<AvailableRefundMethod>) => {
      const promises = options.map((element) => getOptions(element));
      const values = await Promise.all(promises);
      if (isArray(values)) {
        setMianRefund(values.filter((element) => element?.title) as Array<AskUser>);
      }
    };

    if (state?.orderDetail?.refundInformation?.availableRefundMethods) {
      setOptions(state?.orderDetail?.refundInformation?.availableRefundMethods);
    }
  }, [state?.orderDetail?.refundInformation?.availableRefundMethods]);

  const onSelectOption = (id: string) => {
    dispatch({ type: 'selectRefundMethod', payload: id });
  };

  return {
    extraRefund: extraRefund || [],
    mainRefund: mainRefund || [],
    country: state?.orderDetail?.countryCode as string,
    onSelectOption,
  };
};
