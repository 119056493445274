import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import type { HTMLAttributes } from 'react';

const TitleContainerDown = styled.div`
  text-align: center;
  padding-top: ${(props) => props.theme.space('space-04')};
  padding-bottom: ${(props) => props.theme.space('space-16')};
`;

const TitleDown = styled(Typography)`
  width: 272px;
  width: ${(props) => (!props.isWeb ? '272px' : '350px')};
  display: inline-block;
  padding: 0;
  margin: 0;
`;

const TitleUpContainer = styled.div`
  text-align: center;
  padding-top: ${(props) => props.theme.space('space-16')};
`;

const TitleUp = styled(Typography)`
  margin: 0;
  padding: 0;
  text-align: center;
  width: ${(props) => (!props.isWeb ? '272px' : '350px')};
  display: inline-block;
`;

interface ButtonActionsContainerProps extends HTMLAttributes<HTMLDivElement> {
  isWeb?: boolean;
}
const ButtonActionsContainer = styled.div<ButtonActionsContainerProps>`
  text-align: center;
  margin: 0;
  padding: 0;
  padding-bottom: ${(props) => props.theme.space('space-10')};
  width: 100%;
  display: flex;
  gap: ${(props) => props.theme.space('space-04')};
  flex-direction: ${(props) => (props.isWeb ? 'row' : 'column')};
  position: ${(props) => (!props.isWeb ? 'fixed' : 'static')};
  bottom: 0;
  left: 0;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;
`;

interface ButtonContainerProps extends HTMLAttributes<HTMLDivElement> {
  isWeb?: boolean;
}
const ButtonContainer = styled.div<ButtonContainerProps>`
  text-align: center;
  margin: 0;
  min-width: 170px;
  padding: ${(props) => (!props.isWeb ? '0px 16px 0px 16px' : '0px')};
  &:first-child {
    order: ${(props) => (!props.isWeb ? 0 : 1)};
  }
`;

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  isWeb?: boolean;
  height?: number;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height && !props.isWeb ? `${props.height - 170}px` : 'auto')};
`;

export {
  TitleContainerDown,
  ButtonActionsContainer,
  ButtonContainer,
  TitleDown,
  TitleUp,
  TitleUpContainer,
  Container,
};
