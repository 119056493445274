import React, { useMemo, memo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Routes } from '@app/cancellation/infra/config/routes';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import { Depedencies } from '@app/cancellation/domain/models';
import type { FC } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import loadable from '@loadable/component';
/**
 * Components
 */
import { NotFound } from '@app/shared/components/v2/NotFound';
import { ProtectedRoute } from '@app/shared/components/ProtectedRoute';
/**
 * Pages
 */
import { Retention } from '@app/cancellation/presentation/v2/pages/Retention';
const Reason = loadable(() => import('@app/cancellation/presentation/v2/pages/Reasons'), {
  resolveComponent: (component) => component.Reasons,
  fallback: <PeyaLoader position="center" />,
});
const Detail = loadable(() => import('@app/cancellation/presentation/v2/pages/Details'), {
  resolveComponent: (component) => component.Details,
  fallback: <PeyaLoader position="center" />,
});
const Help = loadable(() => import('@app/cancellation/presentation/v2/pages/Help'), {
  resolveComponent: (component) => component.Help,
  fallback: <PeyaLoader position="center" />,
});
const AskUser = loadable(() => import('@app/cancellation/presentation/v2/pages/AskUser'), {
  resolveComponent: (component) => component.AskUser,
  fallback: <PeyaLoader position="center" />,
});

type Props = {
  dependencies: Depedencies;
};

export const Router: FC<Props> = WithDependencies(
  memo(({ dependencies }) => {
    const { state } = dependencies.useCancellationContext();
    const { path } = useRouteMatch();
    const availableAskUser = useMemo(() => {
      return (
        state?.orderDetail?.refundInformation?.availableRefundMethods &&
        state?.orderDetail?.refundInformation?.availableRefundMethods.length > 0
      );
    }, [state]);

    return (
      <Switch>
        <Route exact path={path}>
          <Retention />
        </Route>
        <ProtectedRoute
          path={`${path}/${Routes.CANCELLATION_REASON}`}
          condition={state?.orderDetail?.quoteId}
        >
          <Reason />
        </ProtectedRoute>
        <Route path={`${path}/${Routes.CANCELlATION_DETAIL}`}>
          <Detail />
        </Route>
        <ProtectedRoute
          exact
          path={`${path}/${Routes.CANCELLATION_ASK_USER}`}
          condition={availableAskUser}
        >
          <AskUser />
        </ProtectedRoute>
        <Route exact path={`${path}/${Routes.CANCELLATION_HELP}`}>
          <Help />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  }),
);
