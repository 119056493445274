import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from '@app/shared/config/routes';
import { NotFound } from '@app/shared/components/NotFound';
import { Cancellation } from '@app/cancellation/main';

export const Router = () => {
  return (
    <Switch>
      <Route path={Routes.CANCELLATION}>
        <Cancellation />
      </Route>
      <Route
        path={Routes.CANCELlATION_DETAIL}
        children={({ match }) => {
          return (
            <Redirect
              to={Routes.CANCELlATION_DETAIL_REDIRECT.replace(
                ':orderId',
                match?.params.orderId as string,
              )}
            />
          );
        }}
      ></Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
