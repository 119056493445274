import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
/**
 * checkOptionDetail
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOptionDetail = (fact, context) => {
  if (
    context.parameters.businessType === '*' &&
    indexOf(context.parameters.businessExcept || [], fact.businessType) === -1 &&
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.hasCompensation === fact.hasCompensation &&
    indexOf(context.parameters.reason || [], fact.reason) !== -1 &&
    isEqual(context.parameters.fwfKey, fact.fwfKey) &&
    context.parameters.userType === fact.userType
  ) {
    return fact;
  } else if (
    context.parameters.businessType === fact.businessType &&
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.hasCompensation === fact.hasCompensation &&
    indexOf(context.parameters.reason || [], fact.reason) !== -1 &&
    isEqual(context.parameters.fwfKey, fact.fwfKey) &&
    context.parameters.userType === fact.userType
  ) {
    return fact;
  }

  return null;
};

/**
 * checkOptionsDetailLocal
 *
 * @description Function to check options passed to process with rules for local reasons
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOptionsDetailLocal = (fact, context) => {
  if (
    context.parameters.businessType === '*' &&
    indexOf(context.parameters.businessExcept || [], fact.businessType) === -1 &&
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.hasCompensation === fact.hasCompensation &&
    indexOf(context.parameters.reason || [], fact.reason) !== -1 &&
    indexOf(context.parameters.localReason || [], fact.localReason) !== -1 &&
    context.parameters.userType === fact.userType
  ) {
    return fact;
  } else if (
    context.parameters.businessType === fact.businessType &&
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.hasCompensation === fact.hasCompensation &&
    indexOf(context.parameters.reason || [], fact.reason) !== -1 &&
    indexOf(context.parameters.localReason || [], fact.localReason) !== -1 &&
    context.parameters.userType === fact.userType
  ) {
    return fact;
  }

  return null;
};

/**
 * checkOptionDetailUserType
 *
 * @description Function to check options passed to process with rules for guest user
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOptionDetailUserType = (fact, context) => {
  if (
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.businessType === fact.businessType &&
    context.parameters.userType === fact.userType
  ) {
    return fact;
  }

  return null;
};

/**
 * getTranslationDetail
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslationDetail = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
