import React, { useReducer } from 'react';
import { CancellationContext, InitialState } from './context';
import { CancellationReducer } from './reducer';
import type { FC, PropsWithChildren } from 'react';

export const CancellationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(CancellationReducer, InitialState);

  return (
    <CancellationContext.Provider value={{ state, dispatch }}>
      {children}
    </CancellationContext.Provider>
  );
};
