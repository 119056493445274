import { createContext, useContext, Dispatch } from 'react';
import { CancellationActions, CancellationState } from '@app/cancellation/data/contexts';

export type ContextType = {
  dispatch: Dispatch<CancellationActions>;
  state: CancellationState;
};

export const InitialState: CancellationState = {
  orderDetail: {
    quoteId: '',
    cancelable: false,
  },
};

export const CancellationContext = createContext<ContextType>({
  state: InitialState,
  dispatch: () => {},
});

export const useCancellationContext = () => {
  return useContext(CancellationContext);
};
