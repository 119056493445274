import { createContext, useContext } from 'react';

type Props = {
  redirect(action: string, params: Object): void;
  emitEvent(name: string, params: Object): void;
};

export const LinkContext = createContext<Props>({
  redirect: () => {},
  emitEvent: () => {},
});

/**
 * Redirect to deeplink or link
 * @name redirect
 * @function
 * @param {String} action Action to create deeplink
 * @param {Object} params Params to build the final url
 */

/**
 * @typedef {Object} useLinkContext
 * @property {redirect} redirect - Function redirect
 */

/**
 * useLinkContext
 *
 * @description Context for create link o deeplink base on plataform
 * @returns { useLinkContext }
 */
export const useLinkContext = () => {
  return useContext(LinkContext);
};
