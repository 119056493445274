import React from 'react';
import QueryClient from '@app/shared/utils/query_client';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import { EntryComponent, GlobalError } from '@app/shared/components';
import { createIntlProvider, getMessages } from '@app/shared/contexts/IntlProvider';
import { LinkProvider } from '@app/shared/contexts/LinkProvider';
import { SentryProvider } from '@app/shared/contexts/SentryProvider';
import { TrackerProvider } from '@app/shared/contexts/TrackerProvider';
import { usePublicEnv, PublicEnvProvider, getPublicEnv } from '@app/shared/contexts/PublicEnv';
import { AuthProvider } from '@app/shared/contexts/AuthProvider';
import { QueryClientProvider } from 'react-query';
import type { FC, PropsWithChildren } from 'react';
import type { TrackerConfig } from '@shared/models/TrackerConfig';

interface Props extends PropsWithChildren {
  context?: any;
  messages?: any;
  name?: string;
  trackerConfig?: TrackerConfig;
  tracker?: { track(): void };
}

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

const IntlProvider = (props: any) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root: FC<Props> = ({
  context = ssrProps?.context ? ssrProps?.context : {},
  messages = ssrProps?.messages ? ssrProps?.messages : {},
  tracker,
  trackerConfig = ssrProps?.trackerConfig ? ssrProps?.trackerConfig : {},
}) => {
  return (
    <GlobalError>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <AuthProvider>
          <SentryProvider>
            <FenixProvider>
              <>
                <IntlProvider initialMessages={messages}>
                  <TrackerProvider tracker={tracker} config={trackerConfig}>
                    <LinkProvider>
                      <QueryClientProvider client={QueryClient}>
                        <EntryComponent />
                      </QueryClientProvider>
                    </LinkProvider>
                  </TrackerProvider>
                </IntlProvider>
              </>
            </FenixProvider>
          </SentryProvider>
        </AuthProvider>
      </PublicEnvProvider>
    </GlobalError>
  );
};

export default Root;
