import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { LinkContext } from './context';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { generateLink } from '@app/shared/utils/deeplink';
import { DEVICES } from '@app/shared/config/device';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { getWindow } from '@app/shared/utils/window';

/**
 * LinkProvider
 *
 * @description Provider for generate link base on device and deviceOS will generate a link or deeplink
 * @param {Object} Props - React Props
 * @returns React.Node
 */
export const LinkProvider: FC<PropsWithChildren> = ({ children }) => {
  const { device, deviceOS } = usePublicEnv();
  const { fire } = useWebViewEvents();

  const contextValue = {
    /**
     * redirect
     *
     * @description Function for make a redirect based on device
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    redirect: (action: string, params: Object) => {
      const wn = getWindow();
      wn.location.href = generateLink(device, deviceOS, action, params || {});
    },
    /**
     * emitEvent
     * @param {*name} name - Name of event to send to webview
     * @param {*} params - Extra params to send to webview
     */
    emitEvent: (name: string, params: Object) => {
      if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
        fire(name);
      }
    },
  };

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};
