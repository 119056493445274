import { Urls } from '@app/cancellation/infra/config/urls';
import { CancellationRepository as ICancellationRepository } from '@app/cancellation/domain/repositories';
import {
  CancelQuoteRequest,
  QuoteRequest,
  QuoteResponse,
  ReasonsRequest,
  ReasonsResponse,
  CancelQuoteResponse,
  DetailRequest,
  DetailResponse,
} from '@app/cancellation/domain/models';
import HttpClient from '@app/shared/utils/http';

export class CancellationRepository implements ICancellationRepository {
  private client: typeof HttpClient;
  constructor() {
    this.client = HttpClient;
  }

  async generateQuote(payload: QuoteRequest): Promise<QuoteResponse> {
    const result = await this.client.get<QuoteResponse>(
      `${Urls.GENERATE_QUOTE}/${payload?.orderId}`,
      {
        params: {
          flow: payload?.flow,
          mock: payload?.mock || false,
        },
      },
    );
    return result.data;
  }
  async getReasons(payload: ReasonsRequest): Promise<ReasonsResponse> {
    const result = await this.client.get<ReasonsResponse>(`${Urls.REASON_CANCELLATION}`, {
      params: {
        flow: payload?.flow,
        orderId: payload?.orderId,
        mock: payload?.mock || false,
      },
    });
    return result.data;
  }
  async cancelQuote(payload: CancelQuoteRequest): Promise<CancelQuoteResponse> {
    const result = await this.client.post<CancelQuoteResponse>(
      `${Urls.SEND_CANCELLATION}/${payload.orderId}`,
      {
        quoteId: payload.quoteId,
        orderRejectionGlobalReason: payload.reason,
        ...payload.extra,
      },
      {
        params: {
          flow: payload?.flow,
          mock: payload?.mock || false,
        },
      },
    );
    return result.data;
  }
  async getDetail(payload: DetailRequest): Promise<DetailResponse> {
    const result = await this.client.get<DetailResponse>(
      `${Urls.DETAIL_ORDER}/${payload?.orderId}`,
      {
        params: {
          flow: payload?.flow,
          mock: payload?.mock || false,
        },
      },
    );
    return result.data;
  }
}
