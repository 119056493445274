/**
 * AskUserExtraRefund
 *
 * @description component to show extra refund methods and amount
 * to refund. This will used by AskUserListOption component
 */
import React, { memo } from 'react';
import { Container, IconContainer, Amount, Label } from './AskUserExtraRefund.style';
import { NumberFormatter } from '@app/shared/components/NumberFormatter';
import type { FC, HTMLAttributes, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string;
  icon: ReactNode;
  amount: number;
  country: string;
  color: string;
}

export const AskUserExtraRefund: FC<Props> = memo(function AskUserExtraRefund({
  label = '',
  icon = <></>,
  amount = 0,
  country = '',
  color = '',
}) {
  return (
    <Container>
      <IconContainer color={color}>{icon}</IconContainer>
      <div>
        <NumberFormatter amount={amount} country={country}>
          {(amount: string) => (
            <Amount
              component="h4"
              token="font-subtitle-highcontrast-sentence-medium"
              color="text-color-primary"
            >
              {amount}
            </Amount>
          )}
        </NumberFormatter>
        <Label token="font-body-midcontrast-sentence-small" color="text-color-primary">
          {label}
        </Label>
      </div>
    </Container>
  );
});
