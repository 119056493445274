import { useState, useCallback, useEffect, useMemo } from 'react';
import { sentryHub } from '@app/shared/utils/sentry';
import { PubSubEvents } from '@app/cancellation/infra/config/pubSubEvents';
import { Constants } from '@app/shared/config/constants';
import { TrackEvents } from '@app/shared/config/trackEvents';
import {
  RetentionController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/Retention';
import type { ConfigPageElement } from '@shared/models/ConfigPage';
import type { SyntheticEvent } from 'react';
import { getItem } from '@app/shared/utils/storage';
import { Depedencies } from '@app/cancellation/domain/models/Dependencies';
/**
 * useQuestion
 *
 * @description Hook for manage all business logic of view Cancellation Questions
 * @returns function
 */
export const useRetention: RetentionController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const [rules, setRules] = useState<ConfigPageElement | null>(null);
  const { orderId } = dpn.useParams<{ orderId: string }>();
  const { state } = dpn.useCancellationContext();
  const { gtmTrack } = dpn.useTracker();
  const Events = dpn.useRetentionEvents({ dependencies: dpn as Depedencies });
  const query = dpn.useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);
  const Pub = dpn.usePub();
  const { isLoading: loadingCancel, mutate } = dpn.useConfirmQuote({
    dependencies: dpn as Depedencies,
  });
  const { data, isLoading, isError } = dpn.useQuery(
    ['quote'],
    ({ signal }) => {
      return dpn.service.generateQuote({
        flow,
        orderId,
        mock: getItem(Constants.HANDLE_MOCK, false),
      });
    },
    {
      retry: 2,
      onSuccess: (response) => {
        setRules(response?.config?.retention as ConfigPageElement);
        Pub(PubSubEvents.ON_LOAD_RETENTION, {
          context: { orderId, flow },
          result: response,
        });
      },
      onError: (e) => {
        sentryHub.setTag('transaction', 'GenereateQuoteError');
        sentryHub.captureException(e);
      },
    },
  );

  /**
   * onBack
   *
   * @description Function for go to order status window
   */
  const onBack = useCallback(
    (e: SyntheticEvent) => {
      if (e instanceof Event) {
        e.preventDefault();
      }
      Pub(PubSubEvents.ON_BACK_ACTION_RETENTION, {});
    },
    [Pub],
  );
  /**
   * onCancel
   *
   * @description Function to go to reasons page
   */
  const onCancel = useCallback(
    (e: SyntheticEvent) => {
      Pub(PubSubEvents.ON_CONTINUE_ACTION_RETENTION, {
        context: state?.orderDetail,
        mutate,
      });
    },
    [state?.orderDetail, mutate, Pub],
  );

  return {
    loading: isLoading,
    loadingCancel,
    error: isError,
    cancellation: state,
    onCancel,
    onBack,
    rules,
    flow,
  };
};
