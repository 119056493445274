import { useEffect, useState, useCallback, useMemo } from 'react';
import { sentryHub } from '@app/shared/utils/sentry';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { TrackEvents } from '@app/shared/config/trackEvents';
import { Constants } from '@app/shared/config/constants';
import { MetricsEvents } from '@app/shared/config/metricsEvents';
import {
  DetailsController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/Details';
import { getItem } from '@app/shared/utils/storage';
import type { DetailResponse } from '@app/cancellation/domain/models';

export const useDetails: DetailsController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { orderId } = dpn.useParams<{ orderId: string }>();
  const [detail, setDetail] = useState<DetailResponse | null>(null);
  const { emitEvent, redirect } = dpn.useLinkContext();
  const { gtmTrack } = dpn.useTracker();
  const query = dpn.useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);

  const { data, isError, isLoading } = dpn.useQuery(
    ['orderDetail', orderId],
    ({ signal }) => {
      return dpn.service.getDetail({
        flow,
        orderId,
        mock: getItem(Constants.HANDLE_MOCK, false),
      });
    },
    {
      retry: 2,
      onSuccess: (response) => {
        setDetail((state) => response);
      },
      onError: (e) => {
        sentryHub.setTag('transaction', 'DetailOrderError');
        sentryHub.captureException(e);
        dpn.metrics.sendEvent(MetricsEvents.DETAIL_ERROR);
      },
    },
  );

  /**
   * useeffect for send metrics
   */
  useEffect(() => {
    if (data) {
      dpn.metrics.sendEvent(MetricsEvents.DETAIL_LOADED);
      if (data?.refund && data?.refund && data?.refund?.methods.length > 0) {
        dpn.metrics.sendEvent(MetricsEvents.DETAIL_LOAD_WITH_REFUNDS);
      } else {
        dpn.metrics.sendEvent(MetricsEvents.DETAIL_LOAD_WITHOUT_REFUNDS);
      }
    }
  }, [data]);

  /**
   * goHome
   *
   * @description Function for go to home
   */
  const goHome = useCallback(
    (e?: Event) => {
      if (e instanceof Event) {
        e.preventDefault();
      }
      emitEvent(WebViewEvents.CLOSE, {});
    },
    [emitEvent, gtmTrack, orderId, flow],
  );

  const redirectToDetail = () => {
    redirect(ACTIONS.DETAIL, { orderId: data?.order.id });
  };

  /**
   * redirectToCoupons
   *
   * @description Function to redirect to cuopons
   */
  const redirectToCoupons = () => {
    redirect(ACTIONS.COUPONS, {});
  };

  return {
    detail: detail,
    loading: isLoading,
    error: isError,
    goHome,
    userInfo: detail?.user,
    vendor: detail?.vendor,
    redirectToDetail,
  };
};
