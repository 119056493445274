/**
 * checkOptionReason
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOptionReason = (fact, context) => {
  if (context.parameters.reason === fact.reason) {
    return fact;
  }

  return null;
};

/**
 * getTranslationReason
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslationReason = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
