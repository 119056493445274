/**
 * checkOptionDetailPayment
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOptionDetailPayment = (fact, context) => {
  if (
    (context.parameters.businessType === '*' ||
      context.parameters.businessType === fact.businessType) &&
    context.parameters.online === fact.online &&
    context.parameters.voucher === fact.voucher &&
    context.parameters.cash === fact.cash &&
    context.parameters.cancelledByUser === fact.cancelledByUser &&
    context.parameters.isGuestUser === fact.isGuestUser
  ) {
    return fact;
  }

  return null;
};

/**
 * getTranslationDetail
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslationDetailPayment = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
