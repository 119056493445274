/**
 * AskUserOption
 *
 * @description Component to be used by component AskUserListOption, this will
 * show the different refund methods like wallet or credit card
 */
import React, { memo } from 'react';
import RadioButton from '@pedidosya/web-fenix/atoms/RadioButton';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import {
  ListContainer,
  RadioContainer,
  TagContainer,
  ContentContainer,
  Label,
  Description,
  LabelContainer,
} from './AskUserOption.style';
import type { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string;
  description: string;
  tag: string;
  selected: boolean;
  onSelect(id: string): void;
  id: string;
}

export const AskUserOption: FC<Props> = memo(
  ({ label, description, tag, selected, onSelect = () => {}, id, ...props }) => {
    return (
      <ListContainer {...props}>
        <ContentContainer onClick={() => onSelect(id)}>
          <div>
            <LabelContainer>
              <Label
                component="h4"
                token="font-subtitle-highcontrast-sentence-medium"
                color="shape-color-surface-secondary"
              >
                {label}
              </Label>
              {tag && (
                <TagContainer>
                  <Tag label={tag} category="loud" size="small" />
                </TagContainer>
              )}
            </LabelContainer>
            <Description
              token="font-body-midcontrast-sentence-small"
              component="p"
              color="text-color-tertiary"
            >
              {description}
            </Description>
          </div>
          <RadioContainer data-testid="ask_user_item_radio">
            <RadioButton selected={selected ? true : false} />
          </RadioContainer>
        </ContentContainer>
      </ListContainer>
    );
  },
);
