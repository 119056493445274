import React, { memo, useMemo, useState } from 'react';
import Input from '@pedidosya/web-fenix/atoms/Input';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { TrackEvents } from '@app/shared/config/trackEvents';
import { Constants } from '@app/shared/config/constants';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { Title, Container, ButtonContainer, InputContainer } from './ReasonDisclaimer.style';
import Translations from './ReasonDisclaimer.translation';
import type { FC, HTMLAttributes, SyntheticEvent, ChangeEvent } from 'react';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { Depedencies } from '@app/cancellation/domain/models/Dependencies';

interface Props extends HTMLAttributes<HTMLElement> {
  disclaimerType: string;
  disclaimerTitle: string;
  disclaimerElementTitle?: string;
  showRemedy?: boolean;
  deeplink?: string;
  orderId: string;
  onAddExtraValue?(object?: Object): void;
  fieldToAdd?: string;
  isWeb?: boolean;
  dependencies?: Depedencies;
}

export const ReasonDisclaimer: FC<Props> = WithDependencies(
  memo(
    ({
      disclaimerType = '',
      disclaimerTitle = '',
      disclaimerElementTitle = '',
      showRemedy = false,
      deeplink = '',
      orderId = '',
      onAddExtraValue = () => {},
      fieldToAdd = '',
      isWeb = false,
      dependencies = {},
    }) => {
      const [value, setValue] = useState('');
      const dpn = dependencies as Depedencies;
      const { formatMessage } = dpn.useIntl();
      const { redirect } = dpn.useLinkContext();
      const { gtmTrack } = dpn.useTracker();

      const handleRedirect = () => {
        if (gtmTrack) {
          gtmTrack(TrackEvents.CANCEL_REMEDY, {
            orderId: orderId,
            origin: Constants.ORIGEN,
            interactionType: deeplink === ACTIONS.ADDITEMS ? 'Add_item' : 'Change_address',
          });
        }
        redirect(deeplink, { orderId });
      };
      /**
       * isDisclaimerApplicableForButton
       *
       * @description Function to know if a reasons is applicable a remedy
       * for button type
       */
      const isDisclaimerApplicableForButton = useMemo(() => {
        return disclaimerType == 'button' && showRemedy && !isWeb;
      }, [disclaimerType, showRemedy, isWeb]);

      /**
       * isDisclaimerApplicableForInput
       *
       * @description Function to know if a reasons is applicable a remedy
       * for input type
       */
      const isDisclaimerApplicableForInput = useMemo(() => {
        return disclaimerType == 'input' && showRemedy;
      }, [disclaimerType, showRemedy]);

      /**
       * handleChange
       *
       * @description Function to handle input changes
       * @param {Event} event - InputEvent
       */
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      };
      /**
       * emitValue
       *
       * @description Function to emit the event with te value
       * in object format
       * @param {Event} event - InputEvent
       */
      const emitValue = (event: SyntheticEvent) => {
        if (onAddExtraValue && fieldToAdd) {
          onAddExtraValue({ [fieldToAdd]: value });
        }
      };

      return (
        <>
          {isDisclaimerApplicableForButton && (
            <Container data-testid="disclaimer">
              <Title component="p" token="font-subtitle-highcontrast-sentence-medium">
                {formatMessage((Translations as any)[disclaimerTitle])}
              </Title>
              <ButtonContainer>
                <Button
                  label={formatMessage((Translations as any)[disclaimerElementTitle])}
                  hierarchy="primary"
                  size="medium"
                  type="button"
                  onClick={handleRedirect}
                />
              </ButtonContainer>
            </Container>
          )}

          {isDisclaimerApplicableForInput && (
            <Container data-testid="disclaimer">
              <InputContainer>
                <Input
                  value={value}
                  onChange={handleChange}
                  onBlur={emitValue}
                  label={formatMessage((Translations as any)[disclaimerElementTitle])}
                />
              </InputContainer>
            </Container>
          )}
        </>
      );
    },
  ),
);
