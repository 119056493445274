export enum PaymentMethods {
  COUPON = 'COUPON',
  CASH = 'CASH',
  ONLINE = 'ONLINE',
  WALLET = 'WALLET',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  CARD = 'CARD',
  VOUCHER = 'VOUCHER',
  BANK_TRANSFER = 'BANK_TRANSFER',
}
