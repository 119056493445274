import axios from 'axios';
import type { AxiosInstance } from 'axios';

class HTTP {
  private _instance: AxiosInstance;
  constructor() {
    this._instance = axios.create({ timeout: 5000 });
  }
  get instance(): AxiosInstance {
    return this._instance;
  }
}

const HttpService = new HTTP();

export default HttpService.instance;
