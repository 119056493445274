import styled from 'styled-components';
import type { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  height?: string;
  light?: boolean;
  width?: number;
  marginTop?: string;
  marginBottom?: string;
  margin?: string;
}

export const SeparatorLine = styled.div<Props>`
  position: relative;
  border-bottom: solid ${({ height = '1px' }) => height};
  border-bottom-color: ${({ theme, light }) => (light ? '#eae3e3' : '#585065')};
  margin-left: ${({ width = 100 }) => `${100 - width}%`};
  width: ${({ width = 100 }) => `${width}%`};
  ${({ margin = '0', marginTop, marginBottom }) =>
    margin &&
    `margin-top: ${marginTop || margin};
    margin-bottom: ${marginBottom || margin};
`};
`;
