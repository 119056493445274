import { useMemo } from 'react';
import { Routes, RouterUrl } from '@app/cancellation/infra/config/routes';
import { TrackEvents } from '@app/shared/config/trackEvents';
import { Constants } from '@app/shared/config/constants';
import { sentryHub } from '@app/shared/utils/sentry';
import type {
  ConfirmQuoteController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/ConfirmQuote';
import { getItem } from '@app/shared/utils/storage';
import type { Reason } from '@root/shared/models/Reason';
import type { CancelOrderResponse } from '@root/server/cancellation/models';
import type { AxiosError } from 'axios';

export const useConfirmQuote: ConfirmQuoteController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { state, dispatch } = dpn.useCancellationContext();
  const { push } = dpn.useHistory();
  const { gtmTrack } = dpn.useTracker();
  const query = dpn.useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);
  const BasePath = useMemo(
    () => RouterUrl.getBasePath(state?.orderDetail?.orderId as string),
    [state?.orderDetail],
  );

  const { isLoading, mutate, isError } = dpn.useMutation({
    mutationKey: ['confirmQuote', state?.orderDetail as Object],
    mutationFn: () => {
      const { orderId, quoteId } = state?.orderDetail;
      const { globalRejectionReason, code } = state?.reasonSelected as Reason;
      const extra = { ...state?.extra, orderRejectionInternalReason: code };
      if (state?.selectedRefundMethod) {
        extra['selectedRefundMethod'] = state?.selectedRefundMethod;
      }
      return new Promise(async (resolve, rejected) => {
        try {
          const result = await dpn.service.cancelQuote({
            orderId: orderId as string,
            quoteId: quoteId as string,
            reason: globalRejectionReason,
            flow,
            extra,
            mock: getItem(Constants.HANDLE_MOCK, false),
          });
          setTimeout(() => {
            resolve(result);
          }, Constants.TIME_AWAITED_CONFIRM);
        } catch (e) {
          rejected(e);
        }
      });
    },
    retry: 2,
    onSuccess: (response: CancelOrderResponse) => {
      dispatch({ type: 'setCancellation', payload: { ...response, executed: true } });
      /**
       * Sending event to GTM
       */
      if (gtmTrack) {
        gtmTrack(TrackEvents.CANCEL_CLICKED, {
          position: state?.reasonSelected?.position,
          orderId: state?.orderDetail?.orderId,
          cancel_reason: state?.reasonSelected?.message,
          origin: flow,
          selectedRefundMethod: state?.selectedRefundMethod,
        });
      }
      const urlBase = Routes.CANCELlATION_DETAIL.replace(
        ':orderId',
        state?.orderDetail?.orderId as string,
      );
      push(`${BasePath}/${urlBase}?flow=${flow}`);
    },
    onError: (e: AxiosError) => {
      sentryHub.setTag('transaction', 'CancelOrderError');
      sentryHub.captureException(e);
      push(`${BasePath}/${Routes.CANCELLATION_HELP}`);
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
