import { useEffect, useMemo } from 'react';
import { Routes, RouterUrl } from '@app/cancellation/infra/config/routes';
import { sentryHub } from '@app/shared/utils/sentry';
import { TrackEvents } from '@app/shared/config/trackEvents';
import { Constants } from '@app/shared/config/constants';
import { ReasonRules } from '@app/cancellation/use_cases/utils/engine';
import {
  ReasonsController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/Reasons';
import { getItem } from '@app/shared/utils/storage';
import type {
  ReasonRule,
  ReasonsRuleTransformed,
} from '@app/cancellation/domain/models/ReasonRule';
import { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import type { Reason } from '@shared/models/Reason';
import Translations from '@app/cancellation/presentation/pages/Reasons/Reasons.translations';
import filter from 'lodash/filter';
import map from 'lodash/map';

export const useReasons: ReasonsController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { goBack, push } = dpn.useHistory();
  const { formatMessage } = dpn.useIntl();
  const { state, dispatch } = dpn.useCancellationContext();
  const { gtmTrack } = dpn.useTracker();
  const query = dpn.useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);
  const { isLoading: loadingCancel, mutate } = dpn.useConfirmQuote({
    dependencies: dependencies as Depedencies,
  });
  const BasePath = useMemo(
    () => RouterUrl.getBasePath(state?.orderDetail?.orderId as string),
    [state?.orderDetail],
  );

  const { data, isError, isLoading } = dpn.useQuery(
    ['reasons', state?.orderDetail?.orderId],
    ({ signal }) => {
      return dpn.service.getReasons({
        flow,
        orderId: state.orderDetail.orderId as string,
        mock: getItem(Constants.HANDLE_MOCK, false),
      });
    },
    {
      retry: 2,
      enabled: !state?.reasons || state?.reasons?.length == 0,
      onSuccess: async (response) => {
        const finalResult = await reasonsFunc(response);
        dispatch({ type: 'setReasons', payload: finalResult });
      },
      onError: (e) => {
        sentryHub.setTag('transaction', 'ListReasonsError');
        sentryHub.captureException(e);
        push(`${BasePath}/${Routes.CANCELLATION_HELP}`);
      },
    },
  );

  /**
   * reasonsFunc
   *
   * @description Function to get reasons with it's remedies
   * @param {Array<Object>} reasons - Response of service with reasons
   * @returns {Promise<Array<Object>>}
   */
  async function reasonsFunc(reasons: Array<Reason>): Promise<Array<ReasonsRuleTransformed>> {
    const reasonsPromise = map(reasons, (element) => {
      return new Promise((resolve) => {
        ReasonRules({ reason: element.code })
          .then((value: Object) => {
            if (value) {
              return resolve({
                ...element,
                ...value,
                message: formatMessage((Translations as any)[(value as ReasonRule).title]),
                showDisclaimer: false,
              } as ReasonsRuleTransformed);
            }
            return resolve(null);
          })
          .catch((e) => {
            resolve(null);
          });
      });
    });
    const finalReasons = await Promise.all(reasonsPromise);
    return filter(finalReasons, (item) => item !== null) as Array<ReasonsRuleTransformed>;
  }

  const allowCancel = useMemo(() => {
    if (state.reasons) {
      return state?.reasons.some((element) => element.selected);
    }
    return false;
  }, [state?.reasons]);

  /**
   * cancelOrder
   *
   * @description Function for send reason of cancellation to server
   */
  const cancelOrder = async () => {
    /**
     * If order has refund methods availables redirect
     * to ask user page
     */
    if (state?.hasRefundMethods) {
      return push(`${BasePath}/${Routes.CANCELLATION_ASK_USER}?flow=${flow}`);
    }

    if (!loadingCancel && allowCancel) {
      mutate();
    }
  };

  return {
    goBack,
    loading: isLoading,
    error: isError,
    allowCancel,
    loadingCancel,
    cancelOrder,
    toAskUser: state.hasRefundMethods as boolean,
  };
};
