/**
 * Constants
 *
 * @description general constants
 */
export enum Constants {
  HANDLE_MOCK = 'mockRequest',
  DEEPLINK = 'deeplink',
  WEBEVENT = 'webevent',
  ORIGEN = 'order-cancellation',
  HELP_ACTION_ONLINE_HELP = 'online_help',
  HELP_ACTION_BACK_ORDER = 'back_order',
  HELP_ACTION_HOME = 'back_home',
  FEEDBACK_ACTION_HOME = 'back_home',
  FEEDBACK_ACTION_OTHER_RESTAURANT = 'other_restaurant',
  FEEDBACK_ACTION_OTHER_STORE = 'other_store',
  FEEDBACK_ACTION_REPEAT_ORDER = 'repeat_order',
  HELP_CENTER_FLOW = 'help-center',
  LAYOUT_WEB_MIN_WIDTH = 800,
  HIGH_DELAY_FLOW = 'order-status-high-delay',
  UNDISPATCH_FLOW = 'order-status-undispatch',
  HELP_CENTER_CANCELLATION = 'help-center-omad',
  OTHER_STORE_EVENT = 'other',
  OTHER_STORE_QC_EVENT = 'otherQC',
  SAME_STORE = 'repeat',
  TIME_AWAITED_CONFIRM = 4000,
}
