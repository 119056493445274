import React, { memo, useCallback } from 'react';
import { ReasonItem } from '@app/cancellation/presentation/components/ReasonItem';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import { Depedencies } from '@app/cancellation/domain/models';
import type { FC } from 'react';

type Props = {
  dependencies?: Depedencies;
};

export const ListReason: FC<Props> = WithDependencies(
  memo(({ dependencies }) => {
    const dpn = dependencies as Depedencies;
    const { state, dispatch } = dpn.useCancellationContext();
    const isWebView = dpn.useIsWebView();

    /**
     * selectReason
     *
     * @description Function to know which option is selected
     * by the user and after use in the request to cancel
     */
    const selectReason = useCallback(
      (code: string) => {
        dispatch({ type: 'selectReason', payload: code });
      },
      [dispatch],
    );
    /**
     * captureRemedyValue
     *
     * @description Function for capturing extra value from remedies
     * like a input o another element and set to context. It's help
     * when the cancel request is made
     */
    const captureRemedyValue = useCallback(
      (obj: Object) => {
        dispatch({ type: 'setExtraValue', payload: { ...obj } });
      },
      [dispatch],
    );

    if (!state.reasons) {
      return null;
    }

    return (
      <>
        {state.reasons.map((item) => {
          return (
            <ReasonItem
              key={item.code}
              isWeb={!isWebView}
              onSelect={selectReason}
              onAddExtraValue={captureRemedyValue}
              orderId={state.orderDetail.orderId as string}
              {...item}
            />
          );
        })}
      </>
    );
  }),
);
