import React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '@app/cancellation/infra/config/routes';
import { Constants } from '@app/shared/config/constants';
import { Shape } from '@app/shared/components/Shape';
import {
  TitleContainerDown,
  ButtonContainer,
  ButtonActionsContainer,
  TitleDown,
  TitleUp,
  TitleUpContainer,
  Container,
} from './Retention.styles';
import { ImageContainer } from '@app/cancellation/presentation/components/ImageContainer';
import { NumberFormatter } from '@app/shared/components/NumberFormatter';
import { LayoutWeb } from '@app/cancellation/presentation/components/LayoutWeb';
import { LayoutMobile } from '@app/cancellation/presentation/components/LayoutMobile';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import Translations from './Retention.translations';
import Button from '@pedidosya/web-fenix/atoms/Button';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import RetentionImage from '@app/assets/images/retention.svg';
import HighDelay from '@app/assets/images/high_delay.svg';
import Image from '@pedidosya/web-fenix/foundations/Image';

type Props = {
  dependencies?: Depedencies;
};

export const Retention: FC<Props> = WithDependencies(({ dependencies }) => {
  const dpn = dependencies as Depedencies;
  const { formatMessage } = dpn.useIntl();
  const { onCancel, onBack, loading, error, cancellation, rules, loadingCancel, flow } =
    dpn.useRetention({ dependencies });
  const { height } = dpn.useWindowSize();
  const { url } = dpn.useRouteMatch();
  const isWeb = dpn.useIsWeb();
  const Layout = isWeb ? LayoutWeb : LayoutMobile;

  if (loading) {
    return (
      <div data-testid="loader">
        <PeyaLoader position="center" />
      </div>
    );
  }

  if (rules && rules?.navigation?.onPageLoadRedirect) {
    return <Redirect to={`${rules?.navigation?.onPageLoadRedirect}?flow=${flow}`} />;
  }

  if (error) {
    return <Redirect to={`${url}/${Routes.CANCELLATION_HELP}`} />;
  }

  return (
    <Layout
      data-testid="question_container"
      onBack={onBack}
      showMock={true}
      showCanvan={false}
      iconType="back"
    >
      <Container height={height} isWeb={isWeb}>
        <div>
          <ImageContainer>
            <Image
              src={flow === Constants.HIGH_DELAY_FLOW ? HighDelay : RetentionImage}
              alt="Bag"
            />
          </ImageContainer>
          <TitleUpContainer>
            {rules?.mainText ? (
              <TitleUp
                component="h3"
                token="font-headline-highcontrast-small"
                color="text-color-primary"
                isWeb={isWeb}
              >
                {formatMessage((Translations as any)[rules?.mainText], {
                  name: cancellation?.orderDetail?.user?.name,
                })}
              </TitleUp>
            ) : (
              <TitleUp
                component="h3"
                token="font-headline-highcontrast-small"
                color="text-color-primary"
                isWeb={isWeb}
              >
                {formatMessage(Translations.mainSecondTitle)}
              </TitleUp>
            )}
          </TitleUpContainer>
          <TitleContainerDown>
            <NumberFormatter
              country={cancellation?.orderDetail?.countryCode as string}
              amount={cancellation?.orderDetail?.totalAmount as number}
            >
              {(amount: string) => {
                return rules?.mainTitle ? (
                  <TitleDown
                    component="p"
                    token="font-label-midcontrast-medium"
                    color="text-color-secondary"
                    isWeb={isWeb}
                  >
                    {formatMessage((Translations as any)[rules?.mainTitle], { value: amount })}
                  </TitleDown>
                ) : (
                  <TitleDown
                    component="p"
                    token="font-label-midcontrast-medium"
                    color="text-color-secondary"
                    isWeb={isWeb}
                  >
                    {formatMessage(Translations.mainTitle, { value: amount })}
                  </TitleDown>
                );
              }}
            </NumberFormatter>
          </TitleContainerDown>
          {cancellation?.orderDetail?.quoteId && (
            <ButtonActionsContainer data-testid="buttons_container" isWeb={isWeb}>
              <ButtonContainer isWeb={isWeb}>
                <Button
                  type="button"
                  hierarchy="primary"
                  label={formatMessage(Translations.cacncel)}
                  onClick={(e) => onCancel(e)}
                  fullWidth={true}
                />
              </ButtonContainer>
              <ButtonContainer isWeb={isWeb}>
                <Button
                  type="button"
                  hierarchy="secondary"
                  label={formatMessage(Translations.backToOrder)}
                  onClick={onBack}
                  fullWidth={true}
                />
              </ButtonContainer>
            </ButtonActionsContainer>
          )}
        </div>
        {loadingCancel && (
          <Shape>
            <PeyaLoader position="center" />;
          </Shape>
        )}
      </Container>
    </Layout>
  );
});
