export enum DEVICESOS {
  ANDROID = 'AndroidOS',
  IOS = 'iOS',
  WEB = 'WEB',
}

export enum DEVICES {
  MOBILE = 'MOBILE',
  LAPTOP = 'LAPTOP',
  TABLET = 'TABLET',
  WEBVIEW = 'WEBVIEW',
}
