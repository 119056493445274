import indexOf from 'lodash/indexOf';
/**
 * checkOption
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOption = (fact, context) => {
  if (
    context.parameters.vertical === fact.vertical &&
    context.parameters.status === fact.status &&
    context.parameters.payment === fact.payment &&
    context.parameters.omad === false &&
    !context.parameters.flow
  ) {
    return fact;
  } else if (
    context.parameters.vertical === fact.vertical &&
    context.parameters.status === '*' &&
    indexOf(context.parameters.exceptStatus || [], fact.status) === -1 &&
    context.parameters.payment === fact.payment &&
    context.parameters.omad === false &&
    !context.parameters.flow
  ) {
    return fact;
  } else if (
    context.parameters.vertical === '*' &&
    indexOf(context.parameters.except || [], fact.vertical) === -1 &&
    context.parameters.status === fact.status &&
    context.parameters.payment === fact.payment &&
    context.parameters.omad === false &&
    !context.parameters.flow
  ) {
    return fact;
  } else if (
    context.parameters.vertical === '*' &&
    indexOf(context.parameters.except || [], fact.vertical) === -1 &&
    context.parameters.status === '*' &&
    indexOf(context.parameters.exceptStatus || [], fact.status) === -1 &&
    context.parameters.payment === fact.payment &&
    context.parameters.omad === false &&
    !context.parameters.flow
  ) {
    return fact;
  } else if (
    context.parameters.vertical === '*' &&
    context.parameters.status === '*' &&
    context.parameters.payment === '*' &&
    context.parameters.omad === fact.omad &&
    context.parameters.flow === '*' &&
    indexOf(context.parameters.exceptFlow || [], fact.flow) === -1
  ) {
    return fact;
  } else if (
    context.parameters.vertical === '*' &&
    context.parameters.status === '*' &&
    context.parameters.omad === fact.omad &&
    context.parameters.flow === fact.flow &&
    context.parameters.payment === fact.payment
  ) {
    return fact;
  }

  return null;
};

/**
 * getTranslation
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslation = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
