import { OrderStatus } from '@app/shared/config/orderStatus';
import { PaymentMethods } from '@app/shared/config/paymentMethods';
import { Constants } from '@app/shared/config/constants';

export const RetentionRule = {
  name: 'retention',
  rules: [
    {
      name: '1',
      description: 'RESTAURANT + Pending',
      when: {
        closure: 'checkOptionRetention',
        vertical: 'RESTAURANT',
        status: OrderStatus.PENDING,
        payment: PaymentMethods.ONLINE,
        paymentExcept: [PaymentMethods.CASH],
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancellation.questions.mainTitle',
        mainText: 'cancellation.questions.restaurantTitle',
      },
    },
    {
      name: '2',
      description: 'RESTAURANT + ALL Status',
      when: {
        closure: 'checkOptionRetention',
        vertical: 'RESTAURANT',
        status: '*',
        payment: PaymentMethods.ONLINE,
        exceptStatus: [OrderStatus.PENDING],
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancellation.questions.mainTitle',
        mainText: 'cancellation.questions.confirmedRestaurantTitle',
      },
    },
    {
      name: '3',
      description: 'ALL Store + Pending',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        except: ['RESTAURANT'],
        payment: PaymentMethods.ONLINE,
        status: OrderStatus.PENDING,
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancellation.questions.mainTitle',
        mainText: 'cancellation.questions.allTitle',
      },
    },
    {
      name: '4',
      description: 'ALL Store + ALL Status',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        except: ['RESTAURANT'],
        status: '*',
        payment: PaymentMethods.ONLINE,
        exceptStatus: [OrderStatus.PENDING],
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancellation.questions.mainTitle',
        mainText: 'cancellation.questions.confirmedAllTitle',
      },
    },
    {
      name: '5',
      description: 'Payment Cash any store + Pending Status',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        except: ['RESTAURANT'],
        status: OrderStatus.PENDING,
        payment: PaymentMethods.CASH,
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancell.question.mainTitleCash',
        mainText: 'cancellation.questions.allTitle',
      },
    },
    {
      name: '6',
      description: 'Payment Cash restaurant + Pending Status',
      when: {
        closure: 'checkOptionRetention',
        vertical: 'RESTAURANT',
        status: OrderStatus.PENDING,
        payment: PaymentMethods.CASH,
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancell.question.mainTitleCash',
        mainText: 'cancellation.questions.restaurantTitle',
      },
    },
    {
      name: '7',
      description: 'Payment Cash any store + Any Status',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        except: ['RESTAURANT'],
        status: '*',
        payment: PaymentMethods.CASH,
        exceptStatus: [OrderStatus.PENDING],
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancell.question.mainTitleCash',
        mainText: 'cancellation.questions.confirmedAllTitle',
      },
    },
    {
      name: '8',
      description: 'Payment Cash restaurant',
      when: {
        closure: 'checkOptionRetention',
        vertical: 'RESTAURANT',
        status: '*',
        exceptStatus: [OrderStatus.PENDING],
        payment: PaymentMethods.CASH,
        omad: false,
      },
      then: {
        closure: 'getTranslationRetention',
        mainTitle: 'cancell.question.mainTitleCash',
        mainText: 'cancellation.questions.confirmedRestaurantTitle',
      },
    },
    {
      name: '9',
      describe: 'OMAD flow',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        status: '*',
        payment: '*',
        omad: true,
        flow: '*',
        exceptFlow: [Constants.HIGH_DELAY_FLOW],
      },
      then: {
        closure: 'getTranslationRetention',
        mainText: 'cancellation.question.omad.title',
        mainTitle: 'cancellation.questions.mainTitle',
      },
    },
    {
      name: '10',
      describe: 'High Delay flow',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        status: '*',
        payment: PaymentMethods.ONLINE,
        omad: true,
        flow: Constants.HIGH_DELAY_FLOW,
      },
      then: {
        closure: 'getTranslationRetention',
        mainText: 'cancellation.question.highDelay.title',
        mainTitle: 'cancellation.question.highDelay.mainTitle',
      },
    },
    {
      name: '11',
      describe: 'High Delay flow + cash',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        status: '*',
        payment: PaymentMethods.CASH,
        omad: true,
        flow: Constants.HIGH_DELAY_FLOW,
      },
      then: {
        closure: 'getTranslationRetention',
        mainText: 'cancellation.question.highDelay.title',
        mainTitle: 'cancellation.question.highDelay.cash.mainTitle',
      },
    },
    {
      name: '12',
      describe: 'Undispatch flow',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        status: '*',
        payment: PaymentMethods.ONLINE,
        omad: true,
        flow: Constants.UNDISPATCH_FLOW,
      },
      then: {
        closure: 'getTranslationRetention',
        mainText: 'cancellation.question.highDelay.title',
        mainTitle: 'cancellation.question.highDelay.mainTitle',
      },
    },
    {
      name: '13',
      describe: 'Undispatch flow + cash',
      when: {
        closure: 'checkOptionRetention',
        vertical: '*',
        status: '*',
        payment: PaymentMethods.CASH,
        omad: true,
        flow: Constants.UNDISPATCH_FLOW,
      },
      then: {
        closure: 'getTranslationRetention',
        mainText: 'cancellation.question.highDelay.title',
        mainTitle: 'cancellation.question.highDelay.cash.mainTitle',
      },
    },
  ],
};
