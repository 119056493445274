import styled from 'styled-components';
import type { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  src: string;
  width?: number;
  height?: number;
  fallback?: string;
  justRadiusLeft?: boolean;
  borderRadius?: number;
  border?: boolean;
  containSize?: boolean;
  state?: 'enable' | 'disable' | string;
}

const ImageContainer = styled.div<Props>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border: ${(props) => (props.justRadiusLeft || !props.border ? '0px' : '1px solid #dbdade')};
  background-image: url(${(props) => props.src});
  -webkit-background-size: ${(props) => (props.containSize ? 'contain' : 'cover')};
  -moz-background-size: ${(props) => (props.containSize ? 'contain' : 'cover')};
  -o-background-size: ${(props) => (props.containSize ? 'contain' : 'cover')};
  background-size: ${(props) => (props.containSize ? 'contain' : 'cover')};
  background-repeat: no-repeat;
  background-position: center center;
  opacity: ${(props) => (props.state === 'enable' ? 1 : 0.3)};
  border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
  -webkit-border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
  -moz-border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
`;

export { ImageContainer };
