import React from 'react';
import App from '@app/root.component';
import { loadableReady } from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';

type Props = {
  tracker: Object;
};

export function beforeHydratation({ tracker }: Props) {
  return new Promise(async (resolve, reject) => {
    loadableReady(async () => {
      resolve({
        App: function AppWrapper() {
          return (
            <BrowserRouter>
              <App tracker={tracker} />
            </BrowserRouter>
          );
        },
      });
    }).catch((e) => {
      reject(e);
    });
  });
}
