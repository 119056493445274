import React from 'react';
import type { FC } from 'react';
import { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useUrlQuery } from '@app/shared/hooks/useUrlQuery';
import { useQuery, useMutation } from 'react-query';
import { useIntl } from 'react-intl';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { MetricsService } from '@app/shared/services';
import { useLinkContext } from '@app/shared/contexts/LinkProvider';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { CancellationService } from '@app/cancellation/use_cases/services/CancellationService';
import { CancellationProvider, useCancellationContext } from '@app/cancellation/data/contexts';
import { IOCProvider } from '@app/shared/contexts/IOCProvider';
import { useTracker } from '@app/shared/contexts/TrackerProvider';
import { useConfirmQuote } from '@app/cancellation/use_cases/hooks/useConfirmQuote';
import { Router } from '@app/cancellation/presentation/components';
import { Router as RouterV2 } from '@app/cancellation/presentation/v2/components/Router';
import { useRetentionEvents } from '@app/cancellation/use_cases/events/useRetentionEvents';
import { usePub, useSub } from '@app/shared/hooks/usePubSub';
import { useRetention } from '@app/cancellation/use_cases/controllers/useRetention';
import { useIsWeb } from '@app/shared/hooks/useIsWeb';
import { useReasons } from '@app/cancellation/use_cases/controllers/useReasons';
import { useDetails } from '@app/cancellation/use_cases/controllers/useDetails';
import { useAskUser } from '@app/cancellation/use_cases/controllers/useAskUser';
import { useHelpEvents } from '@app/cancellation/use_cases/events/useHelpEvents';
import { useHelp } from '@app/cancellation/use_cases/controllers/useHelp';
import useIsWebView from '@app/shared/hooks/useIsWebView';

export const Cancellation: FC = () => {
  const service = new CancellationService();
  const metrics = new MetricsService();
  const { revamp } = usePublicEnv();
  const dependencies: Depedencies = {
    service: service,
    useParams,
    useHistory,
    useUrlQuery,
    useQuery,
    useMutation,
    useIntl,
    useWindowSize,
    useWebViewEvents,
    metrics,
    useLinkContext,
    usePublicEnv,
    useIsWebView,
    useCancellationContext,
    useTracker,
    useConfirmQuote,
    useRetentionEvents,
    usePub,
    useSub,
    useRetention,
    useIsWeb,
    useReasons,
    useDetails,
    useAskUser,
    useHelpEvents,
    useHelp,
    useRouteMatch,
  };

  return (
    <IOCProvider dependencies={dependencies}>
      <CancellationProvider>
        {revamp ? <RouterV2 dependencies={dependencies} /> : <Router dependencies={dependencies} />}
      </CancellationProvider>
    </IOCProvider>
  );
};
