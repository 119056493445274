import React, { useState, useEffect, useCallback } from 'react';
import { MockSwitch } from '@app/shared/components/MockSwitch';
import ArrowLeft from '@pedidosya/web-fenix/icons/ArrowLeft';
import Close from '@pedidosya/web-fenix/icons/Close';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { getWindowY, getWindow } from '@app/shared/utils/window';
import { Banner, Canvan, Item, MockContainer } from './TopBar.style';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import type { FC, HTMLAttributes, MouseEvent, WheelEvent } from 'react';
import throttle from 'lodash/throttle';

interface Props extends HTMLAttributes<HTMLDivElement> {
  iconType?: 'back' | 'close' | string;
  callback?(): void;
  showMock?: boolean;
  showCanvan?: boolean;
  showIconBack?: boolean;
  canvanColor?: 'gray' | 'green' | string;
}

export const TopBar: FC<Props> = ({
  iconType = 'back',
  callback = () => {},
  showMock = false,
  showCanvan = false,
  showIconBack = true,
  canvanColor = 'gray',
}) => {
  const [icon, setIcon] = useState(iconType);
  const [shadow, setShadow] = useState(false);
  const { mock } = usePublicEnv();
  const { width } = useWindowSize();

  const onClickIcon = (e?: MouseEvent<HTMLDivElement>) => {
    if (e instanceof Event) {
      e.preventDefault();
    }
    if (callback) {
      callback();
    }
  };

  const checkScroll = useCallback(
    (e?: WheelEvent<HTMLBodyElement>) => {
      const y = getWindowY(0);
      setShadow(y === 0 ? false : true);
    },
    [setShadow],
  );

  useEffect(() => {
    const handleScroll = throttle((e) => {
      checkScroll();
    }, 500);
    const wn = getWindow();
    if (wn && wn.document) {
      wn.addEventListener('scroll', handleScroll);
      return () => wn.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <Banner shadow={shadow && !showCanvan}>
      <Item onClick={onClickIcon} data-testid="topbar_item">
        {showIconBack &&
          (icon === 'back' ? (
            <span data-testid="topbar_icon_arrow">
              <ArrowLeft size="large" color="default" />
            </span>
          ) : (
            <span data-testid="topbar_icon_close">
              <Close size="large" color="default" />
            </span>
          ))}
      </Item>
      {mock && showMock && (
        <MockContainer data-testid="mock_container">
          <MockSwitch />
        </MockContainer>
      )}
      {showCanvan && <Canvan width={width} data-testid="canvan" canvanColor={canvanColor} />}
    </Banner>
  );
};
