import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const RadioContainer = styled.div`
  padding-left: 0px;
  padding-right: 0px;
  & > div {
    width: 20px;
    height: 20px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const TagContainer = styled.div`
  text-align: left;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;
`;

const Label = styled(Typography)`
  margin: 0px;
  padding: 0px;
  text-align: left;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const Description = styled(Typography)`
  margin: 4px 0px 0px 0px;
  padding: 0px;
  word-wrap: break-word;
  text-align: left;
`;

export {
  RadioContainer,
  ListContainer,
  TagContainer,
  ContentContainer,
  Label,
  Description,
  LabelContainer,
};
