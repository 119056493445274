/**
 * LocalReasons
 *
 * @description Const with Cancellation reasons
 */
export enum LocalReasons {
  UPDATE_ORDER_PAYMENT_METHOD = 'UPDATE_ORDER_PAYMENT_METHOD',
  UPDATE_ORDER_ADDRESS = 'UPDATE_ORDER_ADDRESS',
  FORGET_ADD_PRODUCTS = 'FORGET_ADD_PRODUCTS',
  DONT_WANT_PRODUCTS = 'DONT_WANT_PRODUCTS',
  DELIVERY_ETA_TOO_LONG = 'DELIVERY_ETA_TOO_LONG',
  ORDER_DUPLICATE = 'ORDER_DUPLICATE',
  ORDER_REGRETTED = 'ORDER_REGRETTED',
  COUPON_NOT_LOADED = 'COUPON_NOT_LOADED',
  UNAVAILABLE_TO_FIND = 'UNAVAILABLE_TO_FIND',
  ITEM_UNAVAILABLE = 'ITEM_UNAVAILABLE',
  NEVER_DELIVERED = 'NEVER_DELIVERED',
}
