import { Verticals } from '@app/shared/config/verticals';
import { UserType } from '@app/shared/config/userType';

export const DetailPaymentRule = {
  name: 'detail_payment',
  rules: [
    {
      description: 'Cancelled by user + cash + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: false,
        cash: true,
        cancelledByUser: true,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.userCancelled.title',
        detailText: 'detail.detail',
        ownerText: '',
      },
    },
    {
      description: 'Cancelled by user + cash + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: true,
        cash: true,
        cancelledByUser: true,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.userCancelled.title',
      },
    },
    {
      description: 'Cancelled by user + online + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: true,
        voucher: false,
        cash: false,
        cancelledByUser: true,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.userCancelled.title',
      },
    },
    {
      description: 'Cancelled by user + online + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: true,
        voucher: true,
        cash: false,
        cancelledByUser: true,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.userCancelled.title',
      },
    },
    {
      description: 'Cancelled by user + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: true,
        cash: false,
        cancelledByUser: true,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.userCancelled.title',
      },
    },
    {
      description: 'Cancelled by other + cash + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: false,
        cash: true,
        cancelledByUser: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.otherCancelled.title',
        detailText: 'detail.detail',
        ownerText: '',
      },
    },
    {
      description: 'Cancelled by other + cash + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: true,
        cash: true,
        cancelledByUser: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.otherCancelled.title',
      },
    },
    {
      description: 'Cancelled by other + online + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: true,
        voucher: false,
        cash: false,
        cancelledByUser: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.otherCancelled.title',
      },
    },
    {
      description: 'Cancelled by other + online + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: true,
        voucher: true,
        cash: false,
        cancelledByUser: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.otherCancelled.title',
      },
    },
    {
      description: 'Cancelled by other + voucher + Owner User',
      when: {
        closure: 'checkOptionDetailPayment',
        businessType: '*',
        online: false,
        voucher: true,
        cash: false,
        cancelledByUser: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: 'detail.refund',
        detailText: 'detail.detail',
        ownerText: 'detail.otherCancelled.title',
      },
    },
    {
      description: 'Cancelled by user + Courier + Guest User',
      when: {
        closure: 'checkOptionDetailUserType',
        businessType: Verticals.COURIER,
        cancelledByUser: true,
        userType: UserType.GUEST,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: '',
        detailText: '',
        ownerText: 'detail.userCancelled.title.courier.guestUser',
      },
    },
    {
      description: 'Cancelled by others + Courier + Guest User',
      when: {
        closure: 'checkOptionDetailUserType',
        businessType: Verticals.COURIER,
        cancelledByUser: false,
        userType: UserType.GUEST,
      },
      then: {
        closure: 'getTranslationDetailPayment',
        titleText: '',
        detailText: '',
        ownerText: 'detail.otherCancelled.title.courier.guestUser',
      },
    },
  ],
};
