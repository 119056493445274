import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  mainTitle: {
    id: 'cancellation.reason.mainTitle',
    defaultMessage: '¿Por qu\u00e9 quieres cancelar?',
  },
  option1: {
    id: 'cancellation.reason.option1',
    defaultMessage: 'Me olvidé de agregar productos.',
  },
  option2: {
    id: 'cancellation.reason.option2',
    defaultMessage: 'Elegí mal el método de pago.',
  },
  option3: {
    id: 'cancellation.reason.option3',
    defaultMessage: 'La dirección de entrega es incorrecta.',
  },
  option4: {
    id: 'cancellation.reason.option4',
    defaultMessage: 'Por otro motivo.',
  },
  option5: {
    id: 'cancellation.reason.option5',
    defaultMessage: 'No cargu\u00e9 el cup\u00f3n.',
  },
  option6: {
    id: 'cancellation.reason.option6',
    defaultMessage: 'Mi pedido aparece duplicado.',
  },
  option7: {
    id: 'cancellation.reason.option7',
    defaultMessage: 'Se va a demorar más de lo que pensaba.',
  },
  option8: {
    id: 'cancellation.reason.option8',
    defaultMessage: 'Agregué productos que no quiero.',
  },
  option9: {
    id: 'cancellation.reason.option9',
    defaultMessage: 'No voy a estar para recibir el producto.',
  },
  option10: {
    id: 'cancellation.reason.option10',
    defaultMessage: 'El local no tiene alguno de los productos.',
  },
  confirm: {
    id: 'cancellation.reason.confirm',
    defaultMessage: 'Confirmar cancelación',
  },
  'cancellation.reason.option1': {
    id: 'cancellation.reason.option1',
    defaultMessage: 'Me olvidé de agregar productos.',
  },
  'cancellation.reason.option2': {
    id: 'cancellation.reason.option2',
    defaultMessage: 'Elegí mal el método de pago.',
  },
  'cancellation.reason.option3': {
    id: 'cancellation.reason.option3',
    defaultMessage: 'La dirección de entrega es incorrecta.',
  },
  'cancellation.reason.option4': {
    id: 'cancellation.reason.option4',
    defaultMessage: 'Por otro motivo.',
  },
  'cancellation.reason.option5': {
    id: 'cancellation.reason.option5',
    defaultMessage: 'No cargu\u00e9 el cup\u00f3n.',
  },
  'cancellation.reason.option6': {
    id: 'cancellation.reason.option6',
    defaultMessage: 'Mi pedido aparece duplicado.',
  },
  'cancellation.reason.option7': {
    id: 'cancellation.reason.option7',
    defaultMessage: 'Se va a demorar más de lo que pensaba.',
  },
  'cancellation.reason.option8': {
    id: 'cancellation.reason.option8',
    defaultMessage: 'Agregué productos que no quiero.',
  },
  'cancellation.reason.option9': {
    id: 'cancellation.reason.option9',
    defaultMessage: 'No voy a estar para recibir el producto.',
  },
  'cancellation.reason.option10': {
    id: 'cancellation.reason.option10',
    defaultMessage: 'El local no tiene alguno de los productos.',
  },
  'reason.page.continue': {
    id: 'reason.page.continue',
    defaultMessage: 'Continuar',
  },
});
