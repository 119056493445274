import { GlobalReasons } from '@app/shared/config/globalReason';
import { LocalReasons } from '@app/shared/config/localReasons';
import { Verticals } from '@app/shared/config/verticals';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { FWFKEYS } from '@config/constants';
import { UserType } from '@app/shared/config/userType';

/**
 * Rule for show remedies in cancellation detail page
 * this is compose of two section, primary sectiion and
 * second section, this flow is for the secondary section
 * where the user can read more detail about the reason
 * of cancellation therefore some remedies action
 */
export const DetailRule = {
  name: 'detail',
  rules: [
    {
      description: 'Closed + Restaurant + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.closedRestaurant',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Closed + Restaurant + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closedRestaurant',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Closed + Restaurant + Cancelled by other + compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closed.restaurant.withVoucher',
        remedieSubText: '',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'Closed + Other + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.closedOtherStore',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Closed + Other + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closedOtherStore',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Closed + Other + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CLOSED],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closed.store.withVoucher',
        remedieSubText: '',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'BAD_WEATHER + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.BAD_WEATHER],
        cancelledByUser: true,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.badWeather',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'BAD_WEATHER + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.BAD_WEATHER],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.badWeather',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'BAD_WEATHER + All + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.BAD_WEATHER],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.bad_weather.withVoucher',
        mainButtonText: 'detail.coupon.view',
        remedieSubText: '',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'BLACKLISTED + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.BLACKLISTED],
        cancelledByUser: true,
        businessType: '*',
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        hasCompensation: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.blacklisted',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'BLACKLISTED + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.BLACKLISTED],
        cancelledByUser: false,
        businessType: '*',
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        hasCompensation: false,
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.blacklisted',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'Bad Experience with Solution Problem + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.DUPLICATE_ORDER,
          GlobalReasons.EXTRA_CHARGE_NEEDED,
          GlobalReasons.LATE_DELIVERY,
          GlobalReasons.UNPROFESSIONAL_BEHAVIOUR,
          GlobalReasons.TECHNICAL_PROBLEM,
          GlobalReasons.NO_RESPONSE,
          GlobalReasons.TOO_BUSY,
          GlobalReasons.UNREACHABLE,
          GlobalReasons.COURIER_UNREACHABLE,
        ],
        cancelledByUser: true,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.solutionProblem',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'Bad Experience with Solution Problem + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.DUPLICATE_ORDER,
          GlobalReasons.EXTRA_CHARGE_NEEDED,
          GlobalReasons.LATE_DELIVERY,
          GlobalReasons.UNPROFESSIONAL_BEHAVIOUR,
          GlobalReasons.TECHNICAL_PROBLEM,
          GlobalReasons.NO_RESPONSE,
          GlobalReasons.TOO_BUSY,
          GlobalReasons.UNREACHABLE,
          GlobalReasons.COURIER_UNREACHABLE,
          GlobalReasons.NO_COURIER,
          GlobalReasons.COURIER_ACCIDENT,
          GlobalReasons.REASON_UNKNOWN,
          GlobalReasons.COURIER_ACCIDENT,
          GlobalReasons.REASON_UNKNOWN,
        ],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.solutionProblem',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'Bad Experience with Solution Problem + All + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.LATE_DELIVERY,
          GlobalReasons.UNPROFESSIONAL_BEHAVIOUR,
          GlobalReasons.TECHNICAL_PROBLEM,
        ],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.technical_problems.withVoucher.name',
        mainButtonText: 'detail.coupon.view',
        remedieSubText: 'detail.technical_problems.withVoucher',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'OUTSIDE_SERVICE_HOURS + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.OUTSIDE_SERVICE_HOURS],
        cancelledByUser: true,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.outsideServiceHours',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'OUTSIDE_SERVICE_HOURS + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.OUTSIDE_SERVICE_HOURS],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.outsideServiceHours',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'OUTSIDE_SERVICE_HOURS + All + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.OUTSIDE_SERVICE_HOURS],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.outside_hours.withVoucher',
        mainButtonText: 'detail.coupon.view',
        remedieSubText: '',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'UNABLE_TO_FIND + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_FIND],
        cancelledByUser: true,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.unableToFind',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'UNABLE_TO_FIND + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_FIND],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.goHome',
        remedieSubText: 'detail.unableToFind',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'UNABLE_TO_PAY + All + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_PAY],
        cancelledByUser: true,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.unableToPay',
        mainButtonText: 'detail.goHome',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'UNABLE_TO_PAY + All + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_PAY],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.unableToPay',
        mainButtonText: 'detail.goHome',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 1 + Restaurant + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.OUTSIDE_DELIVERY_AREA,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
        ],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.orderNoTakenRestaruant',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 1 + Restaurant + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.OUTSIDE_DELIVERY_AREA,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
        ],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.orderNoTakenRestaruant',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 1 + Other + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.OUTSIDE_DELIVERY_AREA,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
        ],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.orderNoTakenOther',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 1 + Other + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.OUTSIDE_DELIVERY_AREA,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
        ],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.orderNoTakenOther',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 2 + Restaurant + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
        ],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 2 + Restaurant + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
        ],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 2 + Other + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
        ],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.PHARMACY],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 2 + Other + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
        ],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.PHARMACY],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        mainButtonText: 'detail.orderOther',
        hasCompensation: false,
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG + Restaurant + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.DELIVERY_ETA_TOO_LONG],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG + Restaurant + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.DELIVERY_ETA_TOO_LONG],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.DELIVERY_ETA_TOO_LONG],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.DELIVERY_ETA_TOO_LONG],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'MISTAKE_ERROR + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.MISTAKE_ERROR],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'MISTAKE_ERROR + RESTAURANT + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.MISTAKE_ERROR],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'MISTAKE_ERROR + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.MISTAKE_ERROR],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'MISTAKE_ERROR + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.MISTAKE_ERROR],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        secondaryButtonText: 'detail.goHome',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        secondButtonType: 'webevent',
        secondButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      descripcion: 'VOUCHER_NOT_APPLIED + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.VOUCHER_NOT_APPLIED],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.voucherNotApplied',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion: 'VOUCHER_NOT_APPLIED + RESTAURANT + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.VOUCHER_NOT_APPLIED],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.voucherNotApplied',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion: 'VOUCHER_NOT_APPLIED + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.VOUCHER_NOT_APPLIED],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.voucherNotApplied',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion: 'VOUCHER_NOT_APPLIED + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.VOUCHER_NOT_APPLIED],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.voucherNotApplied',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_PAYMENT_METHOD],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updatePayment',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + RESTAURANT + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_PAYMENT_METHOD],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updatePayment',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_PAYMENT_METHOD],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updatePayment',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_PAYMENT_METHOD],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updatePayment',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_ADDRESS + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_ADDRESS],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updateAddress',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_ADDRESS + RESTAURANT + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_ADDRESS],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updateAddress',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_ADDRESS],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updateAddress',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UPDATE_ORDER_PAYMENT_METHOD + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UPDATE_ORDER_ADDRESS],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updateAddress',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + DONT_WANT_PRODUCTS | FORGET_ADD_PRODUCTS + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.DONT_WANT_PRODUCTS, LocalReasons.FORGET_ADD_PRODUCTS],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updateProducts',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + DONT_WANT_PRODUCTS | FORGET_ADD_PRODUCTS + RESTAURANT + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.DONT_WANT_PRODUCTS, LocalReasons.FORGET_ADD_PRODUCTS],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updateProducts',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + DONT_WANT_PRODUCTS | FORGET_ADD_PRODUCTS + OTHER + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.DONT_WANT_PRODUCTS, LocalReasons.FORGET_ADD_PRODUCTS],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.updateProducts',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + DONT_WANT_PRODUCTS | FORGET_ADD_PRODUCTS + OTHER + Cancelled by other',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.DONT_WANT_PRODUCTS, LocalReasons.FORGET_ADD_PRODUCTS],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.updateProducts',
        mainButtonText: 'detail.repeatOrder',
        secondaryButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        secondButtonType: 'deeplink',
        secondButtonValue: 'coupons',
        hasCompensation: false,
      },
    },
    {
      description: 'Reasons 1 + Restaurant + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.ITEM_UNAVAILABLE,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
          GlobalReasons.NO_RESPONSE,
          GlobalReasons.TOO_BUSY,
          GlobalReasons.UNREACHABLE,
        ],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closed.restaurant.withVoucher',
        remedieSubText: '',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'Reasons 1 + Other + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.ITEM_UNAVAILABLE,
          GlobalReasons.MENU_ACCOUNT_SETTINGS,
          GlobalReasons.WILL_NOT_WORK_WITH_PLATFORM,
          GlobalReasons.BAD_LOCATION,
          GlobalReasons.NO_PICKER,
          GlobalReasons.MOV_NOT_REACHED,
          GlobalReasons.BILLING_PROBLEM,
          GlobalReasons.NO_RESPONSE,
          GlobalReasons.TOO_BUSY,
          GlobalReasons.UNREACHABLE,
        ],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.closed.store.withVoucher',
        remedieSubText: '',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'Reasons 2 + Restaurant + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
          GlobalReasons.COURIER_UNREACHABLE,
          GlobalReasons.COURIER_ACCIDENT,
          GlobalReasons.NO_COURIER,
        ],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.technical_problems.withVoucher.name',
        remedieSubText: 'detail.technical_problems.withVoucher',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'Reasons 2 + Other + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [
          GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
          GlobalReasons.FOOD_QUALITY_SPILLAGE,
          GlobalReasons.CONTENT_WRONG_MISLEADING,
          GlobalReasons.CARD_READER_NOT_AVAILABLE,
          GlobalReasons.COURIER_UNREACHABLE,
          GlobalReasons.COURIER_ACCIDENT,
          GlobalReasons.NO_COURIER,
        ],
        cancelledByUser: false,
        businessType: '*',
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.technical_problems.withVoucher.name',
        remedieSubText: 'detail.technical_problems.withVoucher',
        mainButtonText: 'detail.coupon.view',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      descripcion: 'CONTENT_WRONG_MISLEADING + Pharma + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CONTENT_WRONG_MISLEADING],
        cancelledByUser: true,
        businessType: Verticals.PHARMACY,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.pharma.recipe',
        mainButtonText: 'detail.repeatOrder',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion: 'CONTENT_WRONG_MISLEADING + Pharma + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.CONTENT_WRONG_MISLEADING],
        cancelledByUser: false,
        businessType: Verticals.PHARMACY,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.pharma.recipe',
        mainButtonText: 'detail.repeatOrder',
        mainButtonType: 'deeplink',
        mainButtonValue: 'repeatOrder',
        hasCompensation: false,
      },
    },
    {
      descripcion: 'UNABLE_TO_FIND + Courier + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_FIND],
        cancelledByUser: true,
        businessType: Verticals.COURIER,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: true,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.courier.unable',
        mainButtonText: 'detail.goHome',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      descripcion: 'UNABLE_TO_FIND + Courier + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.UNABLE_TO_FIND],
        cancelledByUser: false,
        businessType: Verticals.COURIER,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: true,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.courier.unable',
        mainButtonText: 'detail.goHome',
        mainButtonType: 'webevent',
        mainButtonValue: WebViewEvents.CLOSE,
        hasCompensation: false,
      },
    },
    {
      description: 'Cancelled by user + Courier + Guest User',
      when: {
        closure: 'checkOptionDetailUserType',
        businessType: Verticals.COURIER,
        cancelledByUser: true,
        userType: UserType.GUEST,
      },
      then: {
        closure: 'getTranslationDetail',
        title: '',
        mainButtonText: '',
        mainButtonType: '',
        mainButtonValue: '',
        hasCompensation: false,
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.solutionProblem.courier.guestUser',
      },
    },
    {
      description: 'Cancelled by others + Courier + Guest User',
      when: {
        closure: 'checkOptionDetailUserType',
        businessType: Verticals.COURIER,
        cancelledByUser: false,
        userType: UserType.GUEST,
      },
      then: {
        closure: 'getTranslationDetail',
        title: '',
        mainButtonText: '',
        mainButtonType: '',
        mainButtonValue: '',
        hasCompensation: false,
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.solutionProblem.courier.guestUser',
      },
    },
    {
      description: 'Item Unavailable + Cancelled by user + Restaurante',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.ITEM_UNAVAILABLE],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        businessExcept: [],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.item_unavailable.restaurant',
        remedieSubText: '',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Item Unavailable + Cancelled by other + Restaurante',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.ITEM_UNAVAILABLE],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        businessExcept: [],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.item_unavailable.restaurant',
        remedieSubText: '',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Item Unavailable + Cancelled by user + any store',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.ITEM_UNAVAILABLE],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.COURIER],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.item_unavailable.store',
        remedieSubText: '',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'Item Unavailable + Cancelled by other + any store',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.ITEM_UNAVAILABLE],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.COURIER],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.item_unavailable.store',
        remedieSubText: '',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'NEVER_DELIVERED + Restaurant + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'NEVER_DELIVERED + Restaurant + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: false,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'NEVER_DELIVERED + Any Store + Cancelled by user',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.COURIER],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'NEVER_DELIVERED + Any Store + Cancelled by other',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.RESTAURANT, Verticals.COURIER],
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.badExperiencie',
        remedieSubText: 'detail.findOtherOptions',
        mainButtonText: 'detail.orderOther',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
    {
      description: 'NEVER_DELIVERED + All Store + Cancelled by user + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: true,
        businessType: '*',
        businessExcept: [Verticals.COURIER],
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.technical_problems.withVoucher.name',
        remedieSubText: 'detail.technical_problems.withVoucher',
        mainButtonText: 'detail.coupon.use',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      description: 'NEVER_DELIVERED + All Store + Cancelled by other + Compensation',
      when: {
        closure: 'checkOptionDetail',
        reason: [GlobalReasons.NEVER_DELIVERED],
        cancelledByUser: false,
        businessType: '*',
        businessExcept: [Verticals.COURIER],
        hasCompensation: true,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.otherCancelled.title',
        remedieText: 'detail.technical_problems.withVoucher.name',
        remedieSubText: 'detail.technical_problems.withVoucher',
        mainButtonText: 'detail.coupon.use',
        mainButtonType: 'deeplink',
        mainButtonValue: 'coupons',
        hasCompensation: true,
      },
    },
    {
      descripcion:
        'ORDER_MODIFICATION_NOT_POSSIBLE + UNAVAILABLE_TO_FIND + RESTAURANT + Cancelled by user',
      when: {
        closure: 'checkOptionsDetailLocal',
        reason: [GlobalReasons.ORDER_MODIFICATION_NOT_POSSIBLE],
        localReason: [LocalReasons.UNAVAILABLE_TO_FIND],
        cancelledByUser: true,
        businessType: Verticals.RESTAURANT,
        hasCompensation: false,
        fwfKey: {
          [FWFKEYS.COURIER_REMEDY]: false,
        },
        userType: UserType.OWNER,
      },
      then: {
        closure: 'getTranslationDetail',
        title: 'detail.userCancelled.title',
        remedieText: 'detail.findOtherRestaurant',
        mainButtonText: 'detail.orderRestaurant',
        mainButtonType: 'deeplink',
        mainButtonValue: 'otherOrder',
        hasCompensation: false,
      },
    },
  ], // aqui quede, agregar para otra verticales y otros usuarios
};
