import React, { Component } from 'react';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import { getWindow } from '@app/shared/utils/window';
import { sentryHub } from '@app/shared/utils/sentry';
import { ErrorPage } from '@app/shared/components/ErrorPage';
import type { PropsWithChildren } from 'react';

type Props = {} & PropsWithChildren;
type State = {
  error: any;
  hasError: boolean;
};

export class GlobalError extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  componentDidCatch(error: any) {
    this.setState({ error, hasError: true });
    if (error?.__sentry_captured__) {
      // Allow the hub to re-catch the error if the main sentry instance already caught it
      delete error.__sentry_captured__;
    }
    if (sentryHub) {
      sentryHub.captureException(error);
    }
  }

  reload() {
    const wn = getWindow();
    if (wn) {
      wn.location.reload();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <FenixProvider>
          <ErrorPage />
        </FenixProvider>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
