const SECURITY_LEVEL = {
  PUBLIC: 'PUBLIC', // No need of valid token
  WEB_VIEW: 'WEB_VIEW', // Need App token at least
  WEB_VIEW_PRIVATE: 'WEB_VIEW_PRIVATE', // Need user token
};

const FWFKEYS = {
  COURIER_REMEDY: 'order-cancellation-courier-remedy',
};

const GLOBAL = {
  HIGH_DELAY_FLOW: 'order-status-high-delay',
  DELIVERY_ETA_TOO_LONG: 'DELIVERY_ETA_TOO_LONG',
  UNDISPATCH: 'order-status-undispatch',
  HELP_CENTER_CANCELLATION: 'help-center-omad',
};

module.exports = { SECURITY_LEVEL, FWFKEYS, GLOBAL };
