import { Routes as BaseRoutes } from '@app/shared/config/routes';
/**
 * Routes
 *
 * @description const with microsite routes
 */
export enum Routes {
  HOME = '/',
  CANCELLATION_REASON = 'reason',
  CANCELlATION_EXECUTED = 'executed',
  CANCELlATION_DETAIL = 'details',
  CANCELLATION_ASK_USER = 'refund-method',
  CANCELLATION_HELP = 'help',
  NOT_FOUND = '404',
}

export namespace RouterUrl {
  export function getBasePath(orderId: string) {
    if (orderId) {
      return BaseRoutes.HOME.replace(':orderId', orderId);
    }
    return '';
  }
}
