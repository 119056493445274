import React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '@app/cancellation/infra/config/routes';
import { Constants } from '@app/shared/config/constants';
import { Constants as CancelConstants } from '@app/cancellation/infra/config/Constants';
import { Shape } from '@app/shared/components/Shape';
import { Formatter } from '@app/shared/components/NumberFormatter';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import Translations from './Retention.translations';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';

type Props = {
  dependencies?: Depedencies;
};

export const Retention: FC<Props> = WithDependencies(({ dependencies }) => {
  const dpn = dependencies as Depedencies;
  const { formatMessage } = dpn.useIntl();
  const { onCancel, onBack, loading, error, cancellation, rules, loadingCancel, flow } =
    dpn.useRetention({ dependencies });
  const { url } = dpn.useRouteMatch();
  const amount = Formatter(
    cancellation?.orderDetail?.totalAmount as number,
    cancellation?.orderDetail?.countryCode as string,
  );

  if (loading) {
    return (
      <div data-testid="loader">
        <PeyaLoader position="center" />
      </div>
    );
  }

  if (rules && rules?.navigation?.onPageLoadRedirect) {
    return <Redirect to={`${rules?.navigation?.onPageLoadRedirect}?flow=${flow}`} />;
  }

  if (error) {
    return <Redirect to={`${url}/${Routes.CANCELLATION_HELP}`} />;
  }

  return (
    <>
      <FeedbackPage
        CTAType="Primary+Secondary"
        title={
          rules?.mainText
            ? formatMessage((Translations as any)[rules?.mainText], {
                name: cancellation?.orderDetail?.user?.name,
              })
            : formatMessage(Translations.mainSecondTitle)
        }
        description={
          rules?.mainTitle
            ? formatMessage((Translations as any)[rules?.mainTitle], { value: amount })
            : formatMessage(Translations.mainTitle, { value: amount })
        }
        imageSrc={
          flow === Constants.HIGH_DELAY_FLOW
            ? CancelConstants.RETENTION_IMAGE_DELAY
            : CancelConstants.RETENTION_IMAGE
        }
        showClose={false}
        backAction={onBack}
        firstCTALabel={
          cancellation?.orderDetail?.quoteId ? formatMessage(Translations.cacncel) : null
        }
        onFirstCTAClick={onCancel}
        secondCTALabel={
          cancellation?.orderDetail?.quoteId ? formatMessage(Translations.backToOrder) : null
        }
        onSecondCTAClick={onBack}
        secondCTAState="enabled"
        firstCTAState="enabled"
      />
      {loadingCancel && (
        <Shape>
          <PeyaLoader position="center" />;
        </Shape>
      )}
    </>
  );
});
