import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Title = styled(Typography)`
  margin: 0;
  padding: 0;
  text-align: center;
`;

const SubTitle = styled(Typography)`
  margin: 0;
  padding: 0;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: -22.5px;
  left: 50%;
  margin-left: -21px;
  width: 42px;
  height: 45px;
  text-align: center;
`;

export { Title, SubTitle, ImageContainer };
