import { MetricsRepository } from '@app/shared/repositories';

export class MetricsService {
  private repository: MetricsRepository;

  constructor() {
    this.repository = new MetricsRepository();
  }

  /**
   * sendEvent
   *
   * @description Method for call service of metrics
   * @param {string} event - Event name to increment counter in DD
   */
  public async sendEvent(event: string): Promise<string> {
    return this.repository.sendEvent(event);
  }
}
