import { useMemo } from 'react';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { Constants } from '@app/shared/config/constants';
import { MetricsEvents } from '@app/shared/config/metricsEvents';
import { Routes, RouterUrl } from '@app/cancellation/infra/config/routes';
import { PubSubEvents } from '@app/cancellation/infra/config/pubSubEvents';
import {
  RententionEventsController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/RetentionEvents';
import { LoadRetentionEvent, ContinueRententionEvent } from '@app/cancellation/domain/models';

export const useRetentionEvents: RententionEventsController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { dispatch } = dpn.useCancellationContext();
  const { emitEvent } = dpn.useLinkContext();
  const { goBack, push } = dpn.useHistory();
  const query = dpn.useUrlQuery();
  const isWebView = dpn.useIsWebView();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);

  /**
   * Subscription for listen event when
   * page is totally loaded
   *
   * @property {Object} data - payload
   * @property {Object} data.context - Context
   * @property {Object} data.result - Creation quote's request result
   */
  dpn.useSub(PubSubEvents.ON_LOAD_RETENTION, (data: LoadRetentionEvent) => {
    const { context, result } = data;
    dispatch({
      type: 'setOrderDetail',
      payload: { ...result, orderId: context?.orderId as string, flow: context?.flow as string },
    });
    /**
     * If exist globalReasons and local in the response
     * save like selected reason in the store
     */
    if (result?.orderRejectionInternalReason && result?.orderRegrettedReason) {
      dispatch({
        type: 'setExternalReason',
        payload: {
          orderRejectionInternalReason: result?.orderRejectionInternalReason,
          orderRegrettedReason: result?.orderRegrettedReason,
        },
      });
      dispatch({ type: 'selectReason', payload: result?.orderRejectionInternalReason });
    }

    dpn.metrics.sendEvent(MetricsEvents.RETENTION_LOADED);
  });

  dpn.useSub(PubSubEvents.ON_BACK_ACTION_RETENTION, () => {
    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE, {});
    }
    return goBack();
  });

  dpn.useSub(PubSubEvents.ON_CONTINUE_ACTION_RETENTION, (data: ContinueRententionEvent) => {
    const { context, mutate } = data;
    const BasePath = RouterUrl.getBasePath(context?.orderId as string);
    /**
     * If exist globalReasons and local reason set in store after request
     * to backend to create a quote, must be execute cancellation, because
     * this order from helpcenter
     */
    if (context?.orderRegrettedReason && context?.orderRejectionInternalReason) {
      const refunds = context?.refundInformation;
      if (
        refunds &&
        refunds?.availableRefundMethods &&
        refunds?.availableRefundMethods.length > 0
      ) {
        dispatch({ type: 'selectReason', payload: context?.orderRejectionInternalReason });
        return push(`${BasePath}/${Routes.CANCELLATION_ASK_USER}?flow=${flow}`);
      }

      return mutate({
        orderId: context?.orderId,
        quoteId: context?.quoteId,
        regretReason: context?.orderRegrettedReason,
        flow,
        internalReasons: context?.orderRejectionInternalReason,
      });
    }

    return push(`${BasePath}/${Routes.CANCELLATION_REASON}?flow=${flow}`);
  });
};
