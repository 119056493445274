import React from 'react';
import styled from 'styled-components';
import type { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLAnchorElement>, PropsWithChildren {
  callback?(): void;
}

const LinkActionStyled = styled.a`
  text-decoration: underline;
  color: '#100423';
  text-align: center;
  with: 100%;
  display: block;
  font-size: '12px';
  margin-top: 24px;
  cursor: pointer;
  font-weight: 700;
  line-height: 14px;
`;

export const LinkAction: FC<Props> = ({ children, callback, ...props }) => {
  const onCallback = (e) => {
    if (e instanceof Event) {
      e.preventDefault();
    }
    callback && callback();
  };
  return (
    <LinkActionStyled {...props} onClick={onCallback}>
      {children}
    </LinkActionStyled>
  );
};
