/**
 * Routes
 *
 * @description const with microsite routes
 */
export enum Routes {
  HOME = '/order-cancellation/order/:orderId',
  CANCELlATION_DETAIL = '/order-cancellation/detail/:orderId',
  CANCELlATION_DETAIL_REDIRECT = '/order-cancellation/order/:orderId/details',
  NOT_FOUND = '/order-cancellation/404',
  CANCELLATION = '/order-cancellation/order/:orderId',
}
