import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const ExtraRefundTitle = styled(Typography)`
  margin-top: 48px;
  margin-bottom: 20px;
  padding: 0;
  text-align: left;
`;

const ExtraRefundContainer = styled.div`
  margin-bottom: 20px;
`;

export { ExtraRefundTitle, ExtraRefundContainer };
