import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.section``;

const Title = styled(Typography)`
  text-align: left;
  margin: 0;
  padding: 0px 0px 12px 0px;
`;

const ButtonContainer = styled.div`
  text-align: left;
  & > button {
    width: auto;
    padding: 8px 16px;
  }
`;

const InputContainer = styled.div`
  & > div {
    padding-top: 8px;
    text-align: left;
  }
`;

export { Container, Title, ButtonContainer, InputContainer };
