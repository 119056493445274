import {
  AskUserController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/AskUser';
import { Depedencies } from '@app/cancellation/domain/models/Dependencies';
import isEmpty from 'lodash/isEmpty';

export const useAskUser: AskUserController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { state } = dpn.useCancellationContext();
  const { goBack } = dpn.useHistory();
  const { isLoading: loading, mutate } = dpn.useConfirmQuote({
    dependencies: dependencies as Depedencies,
  });

  /**
   * confirm
   *
   * @description Function to call service to cancel the quote
   */
  const confirm = async () => {
    if (!loading) {
      mutate();
    }
  };

  return {
    allowConfirm: !isEmpty(state?.selectedRefundMethod) || !loading,
    confirm,
    totalAmount: state?.orderDetail?.totalAmount || 0,
    country: state?.orderDetail?.countryCode || '',
    loading,
    goBack,
  };
};
