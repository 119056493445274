import React from 'react';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { useLinkContext } from '@app/shared/contexts/LinkProvider';
import { ACTIONS } from '@app/shared/utils/deeplink';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';

export const NotFound = () => {
  const { emitEvent, redirect } = useLinkContext();
  const isWebView = useIsWebView();

  const handleClose = () => {
    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE, {});
    }

    redirect(ACTIONS.HOME, {});
  };

  return (
    <FeedbackPage
      title="Esta página no existe"
      variant="empty"
      description="Es posible que el link sea incorrecto o que se haya eliminado la página."
      showBack={true}
      showClose={false}
      backAction={handleClose}
      firstCTALabel="Ir al Inicio"
      firstCTAState="enabled"
      onFirstCTAClick={handleClose}
    />
  );
};
