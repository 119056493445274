import React, { memo } from 'react';
import RadioButton from '@pedidosya/web-fenix/atoms/RadioButton';
import { ReasonDisclaimer } from '@app/cancellation/presentation/components/ReasonDisclaimer';
import {
  ItemContainer,
  RadioContainer,
  RadioText,
  ContentContainer,
  DisclaimerContent,
} from './ReasonItem.style';
import type { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  message?: string;
  code?: string;
  selected?: boolean;
  onSelect?(code: string): void;
  showDisclaimer?: boolean;
  onAddExtraValue?(obj: Object): void;
  isWeb?: boolean;
  orderId: string;
  disclaimerType: string;
  disclaimerTitle: string;
  disclaimerElementTitle?: string;
}

export const ReasonItem: FC<Props> = memo(
  ({
    message = '',
    code = '',
    selected = false,
    onSelect = () => {},
    showDisclaimer = false,
    isWeb = false,
    orderId,
    onAddExtraValue = () => {},
    ...props
  }) => {
    return (
      <ItemContainer onClick={() => onSelect(code)} {...props}>
        <RadioContainer data-testid="reason_item_radio">
          <RadioButton selected={selected ? true : false} />
        </RadioContainer>
        <ContentContainer>
          <RadioText component="p" token="font-body-midcontrast-sentence-medium">
            {message}
          </RadioText>
          <DisclaimerContent>
            {showDisclaimer && (
              <ReasonDisclaimer
                isWeb={isWeb}
                onAddExtraValue={onAddExtraValue}
                orderId={orderId}
                {...props}
              />
            )}
          </DisclaimerContent>
        </ContentContainer>
      </ItemContainer>
    );
  },
);
