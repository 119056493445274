import { InitialState } from './context';
import { CancellationActions, CancellationState } from './type';
import { RefundInformation } from '@app/cancellation/domain/models';
import { AvailableRefundMethod } from '@shared/models/Quote';

export const CancellationReducer = (state: CancellationState, action: CancellationActions) => {
  switch (action.type) {
    case 'setReasons':
      return {
        ...state,
        reasons: action.payload.map((element) => {
          return { ...element, selected: false };
        }),
      };
    case 'setOrderDetail': {
      const detail = { ...action.payload };
      const refund = detail?.refundInformation;

      if (refund && refund?.availableRefundMethods) {
        detail.refundInformation.availableRefundMethods = refund.availableRefundMethods.map(
          (element) => {
            if (element.identifier === refund?.predefinedRefundMethod) {
              return { ...element, selected: true };
            }
            return { ...element, selected: false };
          },
        );
      }

      return {
        ...state,
        orderDetail: detail,
        extra: {},
        hasRefundMethods:
          detail?.refundInformation?.availableRefundMethods &&
          detail?.refundInformation?.availableRefundMethods.length
            ? true
            : false,
        selectedRefundMethod: refund?.predefinedRefundMethod,
      };
    }
    case 'selectReason': {
      let reasonSelected;
      const reasonsFinnal = state.reasons || [];
      const reasons = reasonsFinnal.map((element, index) => {
        if (element.code === action.payload) {
          reasonSelected = { ...element, selected: true, position: index + 1 };
          return { ...element, selected: true, showDisclaimer: true };
        }
        return { ...element, selected: false, showDisclaimer: false };
      });
      return { ...state, reasons, extra: {}, reasonSelected: reasonSelected };
    }
    case 'setCancellation': {
      return { ...state, cancellation: action.payload };
    }
    case 'clear': {
      return { ...InitialState };
    }
    case 'setExtraValue': {
      return { ...state, extra: action.payload };
    }
    case 'selectRefundMethod': {
      const detail = state.orderDetail || {};
      const refunds = detail?.refundInformation?.availableRefundMethods.map((element) => {
        if (element.identifier === action.payload) {
          return { ...element, selected: true };
        }
        return { ...element, selected: false };
      });
      detail.refundInformation = detail.refundInformation || ({} as RefundInformation);
      detail.refundInformation.availableRefundMethods = refunds as Array<AvailableRefundMethod>;
      return { ...state, orderDetail: detail, selectedRefundMethod: action.payload };
    }
    case 'setExternalReason': {
      const reasonsFinnal = state.reasons || [];
      const reasons = reasonsFinnal.map((element) => ({ ...element, selected: false }));
      state.reasons = [
        ...reasons,
        {
          selected: true,
          showRemedy: false,
          code: action.payload.orderRejectionInternalReason,
          globalRejectionReason: action.payload.orderRegrettedReason,
        },
      ];
      return state;
    }
    default:
      throw new Error('No action available');
  }
};
