import Engine from 'rules-js';
import { rule } from './flows/feedback-remedies';
import { RetentionRule } from './flows/retention';
import { DetailRule } from './flows/detail';
import { DetailPaymentRule } from './flows/detail_payment';
import { ReasonsRule } from './flows/reasons';
import { AskUserExtraRule } from './flows/ask_user_extra_refund';
import { AskUserMainRule } from './flows/ask_user_main_refund';
import { checkOption, getTranslation } from './closure/remedies';
import {
  checkOption as checkOptionRetention,
  getTranslation as getTranslationRetention,
} from './closure/retention';
import { checkOptionReason, getTranslationReason } from './closure/reasons';
import {
  checkOptionDetail,
  checkOptionDetailUserType,
  getTranslationDetail,
  checkOptionsDetailLocal,
} from './closure/detail';
import { checkOptionDetailPayment, getTranslationDetailPayment } from './closure/detail_payment';
import {
  checkExtraRefundMethod,
  getTranslationRefundMethod,
} from './closure/ask_user_extra_refund';

const RuleEngine = new Engine();
RuleEngine.closures.add('checkOption', checkOption, { required: ['vertical', 'reason'] });
RuleEngine.closures.add('getTranslation', getTranslation, {
  required: ['mainText', 'firstButton', 'secondButton'],
});
RuleEngine.closures.add('checkOptionRetention', checkOptionRetention, {
  required: ['vertical', 'status'],
});
RuleEngine.closures.add('getTranslationRetention', getTranslationRetention, {
  required: ['mainText'],
});
RuleEngine.closures.add('checkOptionDetail', checkOptionDetail, {
  required: ['cancelledByUser', 'businessType', 'reason'],
});
RuleEngine.closures.add('checkOptionsDetailLocal', checkOptionsDetailLocal, {
  required: ['cancelledByUser', 'businessType', 'reason', 'localReason'],
});
RuleEngine.closures.add('getTranslationDetail', getTranslationDetail, {
  required: ['cancelledByUser', 'businessType', 'reason'],
});
RuleEngine.closures.add('getTranslationReason', getTranslationReason, {
  required: ['reason'],
});
RuleEngine.closures.add('checkOptionReason', checkOptionReason, {
  required: ['reason'],
});

RuleEngine.closures.add('getTranslationDetailPayment', getTranslationDetailPayment, {
  required: ['online', 'cash', 'voucher', 'cancelledByUser'],
});
RuleEngine.closures.add('checkOptionDetailPayment', checkOptionDetailPayment, {
  required: ['online', 'cash', 'voucher', 'cancelledByUser'],
});

RuleEngine.closures.add('checkOptionDetailUserType', checkOptionDetailUserType, {
  required: ['businessType', 'cancelledByUser', 'userType'],
});

RuleEngine.closures.add('checkExtraRefundMethod', checkExtraRefundMethod, {
  required: ['refundMethod'],
});
RuleEngine.closures.add('getTranslationRefundMethod', getTranslationRefundMethod, {
  required: ['refundMethod'],
});

RuleEngine.add(rule);
RuleEngine.add(RetentionRule);
RuleEngine.add(DetailRule);
RuleEngine.add(ReasonsRule);
RuleEngine.add(DetailPaymentRule);
RuleEngine.add(AskUserExtraRule);
RuleEngine.add(AskUserMainRule);

/**
 * RemediesRules
 *
 * @description Function for process the params applying a rule for remdies
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.reason - Cancellations reason code
 * @param {string} params.vertical - Vertical or order
 * @returns {Promise<Object>}
 */
export const RemediesRules = async (params) => {
  const result = await RuleEngine.process('feedback-remedies', params);
  return result.fact.translations || null;
};

/**
 * RetentionRules
 *
 * @description Function for process the params applying a rule for retention
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.status - Order status
 * @param {string} params.vertical - Vertical or order
 * @returns {Promise<Object>}
 */
export const RetentionRules = async (params) => {
  const result = await RuleEngine.process('retention', params);
  return result.fact.translations || null;
};

/**
 * DetailRules
 *
 * @description Function for process the params applying a rule for detail
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.reason - Reasons of cancellation
 * @param {string} params.cancelledByUser - Order cancaclled by
 * @param {string} params.businessType - Vertical or order
 * @returns {Promise<Object>}
 */
export const DetailRules = async (params) => {
  const result = await RuleEngine.process('detail', params);
  return result.fact.translations || null;
};

/**
 * DetailRules
 *
 * @description Function for process the params applying a rule for detail
 * @param {Object} params - Parameters to evaluate
 * @param {boolean} params.online - Reasons of cancellation
 * @param {boolean} params.cash - Order cancaclled by
 * @param {boolean} params.voucher - Vertical or order
 * @param {boolean} params.cancelledByUser - Vertical or order
 * @returns {Promise<Object>}
 */
export const DetailPaymentRules = async (params) => {
  const result = await RuleEngine.process('detail_payment', params);
  return result.fact.translations || null;
};

/**
 * ReasonRules
 * @typedef {Object } ReasonResponse
 * @property {string} title
 * @property {string?} disclaimerType - Values can be button | input
 * @property {string?} disclaimerTitle
 * @property {string?} disclaimerElementTitle
 * @property {string?} disclaimerElementAction
 * @property {string?} deeplink - Deeplink to be called
 *
 * @description Function for process the params applying a rule for reasons
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.reason - Reasons of cancellation
 * @returns {Promise<ReasonResponse>}
 */
export const ReasonRules = async (params) => {
  const result = await RuleEngine.process('reasons', params);
  return result.fact.translations || null;
};

/**
 * AskUserExtraRules
 *
 * @description Function for process rule for extra refund method in
 * ask user flow
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.refundMethod - Refund Method (WALLET | CUOPON)
 * @returns {Promise<Object>}
 */
export const AskUserExtraRules = async (params) => {
  const result = await RuleEngine.process('ask_user_extra_refubnd', params);
  return result.fact.translations || null;
};

/**
 * AskUserMainRules
 *
 * @description Function for process rule for main refund method in
 * ask user flow
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.refundMethod - Refund Method (WALLET | CREDIT_CARD | DEBIT_CARD)
 * @returns {Promise<Object>}
 */
export const AskUserMainRules = async (params) => {
  const result = await RuleEngine.process('ask_user_main_refund', params);
  return result.fact.translations || null;
};
