import React from 'react';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { useLinkContext } from '@app/shared/contexts/LinkProvider';
import { ACTIONS } from '@app/shared/utils/deeplink';

export const NotFound = () => {
  const { emitEvent, redirect } = useLinkContext();
  const isWebView = useIsWebView();

  const handleClose = () => {
    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE, {});
    }

    redirect(ACTIONS.HOME, {});
  };

  return (
    <Error errorCode={404} primaryActionLabel="Ir al inicio" primaryActionClick={handleClose} />
  );
};
