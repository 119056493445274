/**
 * AskUserLisOption
 *
 * @description Component to be used by page AskUser, this will
 * show the list of different refund methods like wallet or credit card
 */
import React from 'react';
import Translations from './AskUserListOption.translation';
import { SeparatorLine } from '@app/shared/components';
import { AskUserOption } from '@app/cancellation/presentation/components/AskUserOption';
import { useAskUserListOption } from './useAskUserListOption';
import { AskUserExtraRefund } from '@app/cancellation/presentation/components/AskUserExtraRefund';
import { ExtraRefundTitle, ExtraRefundContainer } from './AskUserListOption.style';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { Depedencies } from '@app/cancellation/domain/models';
import type { FC } from 'react';

type Props = {
  dependencies: Depedencies;
};

export const AskUserListOption: FC<Props> = WithDependencies(({ dependencies }) => {
  const dpn = dependencies as Depedencies;
  const { extraRefund, mainRefund, country, onSelectOption } = useAskUserListOption({
    dependencies,
  });
  const { formatMessage } = dpn.useIntl();

  return (
    <>
      {mainRefund.map((item) => {
        return (
          <AskUserOption
            key={item?.id}
            id={item?.id as string}
            label={formatMessage((Translations as any)[item?.title], { value: item?.value })}
            tag={
              item?.inmediate
                ? formatMessage((Translations as any)['askUser.main.list_option.refund_inmmediate'])
                : ''
            }
            description={formatMessage((Translations as any)[item?.description])}
            onSelect={onSelectOption}
            selected={item?.selected as boolean}
          />
        );
      })}
      <div>
        {extraRefund.length ? (
          <ExtraRefundTitle
            component="h3"
            token="font-label-midcontrast-medium"
            color="text-color-primary"
          >
            {formatMessage((Translations as any)['askUser.extra.list_option.also'])}
          </ExtraRefundTitle>
        ) : (
          <></>
        )}
        {extraRefund.map((item, index) => {
          return (
            <ExtraRefundContainer key={index}>
              <AskUserExtraRefund
                label={formatMessage((Translations as any)[item?.message])}
                country={country}
                amount={item?.amount}
                icon={item?.icon}
                color={item?.color}
              />
            </ExtraRefundContainer>
          );
        })}
      </div>
    </>
  );
});
