import React, { memo } from 'react';
import { TopBar } from '@app/shared/components/TopBar';
import { Container } from '@app/shared/components/Container';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface Props extends HTMLAttributes<HTMLElement>, PropsWithChildren {
  onBack?(): void;
  showMock?: boolean;
  showCanvan?: boolean;
  canvanColor?: 'gray' | 'green' | string;
  iconType?: 'close' | 'back' | string;
  paddingBottom?: number;
}

export const LayoutMobile: FC<Props> = memo(function LayoutMobile({
  children = <></>,
  onBack = () => {},
  showMock = true,
  showCanvan = true,
  canvanColor = 'gray',
  iconType = 'close',
  paddingBottom = 16,
  ...props
}) {
  return (
    <main {...props}>
      <TopBar
        callback={onBack}
        showMock={showMock}
        showCanvan={showCanvan}
        canvanColor={canvanColor}
        iconType={iconType}
      />
      <Container pTop="0" pBottom={paddingBottom}>
        {children}
      </Container>
    </main>
  );
});
