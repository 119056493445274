import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${(props) => props.theme.space('space-08')};
  cursor: pointer;
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
  align-items: flex-start;
  justify-content: flex-start;
`;

const RadioContainer = styled.div`
  & > div {
    width: 20px;
    height: 20px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
`;

const RadioText = styled(Typography)`
  text-align: left;
  margin: 0;
  padding: 0;
`;

const DisclaimerContent = styled.div`
  margin-top: ${(props) => props.theme.space('space-04')};
`;

export { ItemContainer, RadioContainer, ContentContainer, RadioText, DisclaimerContent };
