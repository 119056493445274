import React, { useEffect, useState } from 'react';
import { ImageSkeleton } from '@app/shared/components/ImageSkeleton';
import { ImageContainer } from './ImageLoader.style';
import type { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  src: string;
  width?: number;
  height?: number;
  fallback?: string;
  justRadiusLeft?: boolean;
  borderRadius?: number;
  border?: boolean;
  containSize?: boolean;
  alt?: string;
  state?: 'enable' | 'disable' | string;
}

export const ImageLoader: FC<Props> = ({
  src,
  width = 48,
  height = 48,
  fallback = '',
  state = 'enable',
  justRadiusLeft = false,
  borderRadius = 4,
  border = true,
  containSize = false,
  alt = '',
  ...props
}) => {
  const [source, setSource] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const image = new Image();
    image.src = src;
    image.onload = () => {
      if (isMounted) {
        setSource(src);
      }
    };
    image.onerror = () => {
      setError(true);
    };
    return () => {
      isMounted = false;
    };
  }, [src]);

  if (error && fallback) {
    return (
      <ImageContainer
        width={width}
        height={height}
        data-testid="image"
        src={fallback}
        role="img"
        state={state}
        justRadiusLeft={justRadiusLeft}
        borderRadius={borderRadius}
        border={border}
        {...props}
      />
    );
  }

  if (!source) {
    return (
      <ImageSkeleton
        width={`${width}px`}
        height={`${height}px`}
        shape="square"
        role="img"
        data-testid="image"
      />
    );
  }

  return (
    <ImageContainer
      width={width}
      height={height}
      data-testid="image"
      src={source}
      state={state}
      role="img"
      justRadiusLeft={justRadiusLeft}
      borderRadius={borderRadius}
      border={border}
      containSize={containSize}
      {...props}
    />
  );
};
