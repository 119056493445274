export enum Verticals {
  RESTAURANT = 'RESTAURANT',
  GROCERIES = 'GROCERIES',
  PHARMACY = 'PHARMACY',
  DRINKS = 'DRINKS',
  PETS = 'PETS',
  SHOP = 'SHOP',
  COFFEE = 'COFFEE',
  FLOWERS = 'FLOWERS',
  COURIER = 'COURIER',
  CASH = 'CASH',
  COURIER_BUSINESS = 'COURIER_BUSINESS',
  KIOSKS = 'KIOSKS',
  BILLS = 'BILLS',
}

export namespace VerticalType {
  export function isRestaurant(vertical: string) {
    const bt = vertical ? vertical.toUpperCase() : '';
    return Verticals.RESTAURANT === bt || Verticals.COFFEE === bt;
  }

  export function isQC(vertical: string) {
    const bt = vertical ? vertical.toUpperCase() : '';
    return (
      Verticals.GROCERIES === bt ||
      Verticals.PHARMACY === bt ||
      Verticals.KIOSKS === bt ||
      Verticals.FLOWERS === bt ||
      Verticals.DRINKS === bt ||
      Verticals.PETS === bt
    );
  }
}
