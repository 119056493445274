import { PaymentMethods } from '@app/shared/config/paymentMethods';

export const AskUserMainRule = {
  name: 'ask_user_main_refund',
  rules: [
    {
      description: 'wallet',
      when: {
        closure: 'checkExtraRefundMethod',
        refundMethod: PaymentMethods.WALLET,
      },
      then: {
        closure: 'getTranslationRefundMethod',
        title: 'askUser.main.list_option.wallet.title',
        description: 'askUser.main.list_option.wallet.description',
        inmediate: true,
      },
    },
    {
      description: 'Card',
      when: {
        closure: 'checkExtraRefundMethod',
        refundMethod: PaymentMethods.CARD,
      },
      then: {
        closure: 'getTranslationRefundMethod',
        title: 'askUser.main.list_option.card.title',
        description: 'askUser.main.list_option.card.description',
        inmediate: false,
      },
    },
    {
      description: 'voucher',
      when: {
        closure: 'checkExtraRefundMethod',
        refundMethod: PaymentMethods.VOUCHER,
      },
      then: {
        closure: 'getTranslationRefundMethod',
        title: 'askUser.main.list_option.voucher.title',
        description: 'askUser.main.list_option.voucher.description',
        inmediate: true,
      },
    },
  ],
};
