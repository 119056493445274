import { ACTIONS } from '@app/shared/utils/deeplink';
import { LocalReasons } from '@app/shared/config/localReasons';

export const ReasonsRule = {
  name: 'reasons',
  rules: [
    {
      description: 'FORGET_ADD_PRODUCTS',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.FORGET_ADD_PRODUCTS,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option1',
        disclaimerType: 'button',
        disclaimerTitle: 'reason.addItemsDisclaimer',
        disclaimerElementTitle: 'reason.addItemsButtonDisclaimer',
        disclaimerElementAction: 'reason.addItemsButtonActionDisclaimer',
        deeplink: ACTIONS.ADDITEMS,
      },
    },
    {
      description: 'UPDATE_ORDER_PAYMENT_METHOD',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.UPDATE_ORDER_PAYMENT_METHOD,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option2',
      },
    },
    {
      description: 'UPDATE_ORDER_ADDRESS',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.UPDATE_ORDER_ADDRESS,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option3',
        disclaimerType: 'button',
        disclaimerTitle: 'reason.changeAddressDisclaimer',
        disclaimerElementTitle: 'reason.changeAddressButtonDisclaimer',
        disclaimerElementAction: 'reason.changeAddressButtonActionDisclaimer',
        deeplink: ACTIONS.CHANGEADDRESS,
      },
    },
    {
      description: 'ORDER_REGRETTED',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.ORDER_REGRETTED,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option4',
        disclaimerType: 'input',
        disclaimerTitle: 'reason.regretOrderDisclaimer',
        disclaimerElementTitle: 'reason.regretOrderDisclaimer',
        fieldToAdd: 'orderRegrettedReason',
      },
    },
    {
      description: 'COUPON_NOT_LOADED',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.COUPON_NOT_LOADED,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option5',
      },
    },
    {
      description: 'ORDER_DUPLICATE',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.ORDER_DUPLICATE,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option6',
      },
    },
    {
      description: 'DELIVERY_ETA_TOO_LONG',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.DELIVERY_ETA_TOO_LONG,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option7',
      },
    },
    {
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.DONT_WANT_PRODUCTS,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option8',
      },
    },
    {
      description: 'UNAVAILABLE_TO_FIND',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.UNAVAILABLE_TO_FIND,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option9',
      },
    },
    {
      description: 'ITEM_UNAVAILABLE',
      when: {
        closure: 'checkOptionReason',
        reason: LocalReasons.ITEM_UNAVAILABLE,
      },
      then: {
        closure: 'getTranslationReason',
        title: 'cancellation.reason.option10',
      },
    },
  ],
};
