/**
 * checkExtraRefundMethod
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkExtraRefundMethod = (fact, context) => {
  if (context.parameters.refundMethod === fact.refundMethod) {
    return fact;
  }

  return null;
};

/**
 * getTranslationRefundMethod
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslationRefundMethod = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
