import styled from 'styled-components';
import type { FC, HTMLAttributes, MouseEvent, WheelEvent } from 'react';

interface PropsBanner extends HTMLAttributes<HTMLDivElement> {
  shadow?: boolean;
}

const Banner = styled.div<PropsBanner>`
  margin: 0;
  padding: 8px 0px 8px 0px;
  position: ${(props) => (props.shadow ? 'sticky' : 'block')};
  top: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => (props.shadow ? '1px solid #DBDADE' : '0px')};
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
`;

const Item = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 2;
`;

const MockContainer = styled.div`
  position: relative;
  z-index: 2;
`;

interface PropsCanvan extends HTMLAttributes<HTMLDivElement> {
  shadow?: boolean;
  width?: number;
  canvanColor?: 'gray' | 'green' | string;
}
const Canvan = styled.div<PropsCanvan>`
  position: absolute;
  width: 0;
  top: 0px;
  left: 0px;
  height: 0;
  width: ${(props) => props.width}px;
  background-color: ${(props) => (props.canvanColor === 'gray' ? '#E7E6E9' : '#60F6C0')};
  @media (max-width: 800px) {
    height: 150px;
  }
  @media (min-width: 801px) {
    height: 300px;
  }
`;

export { Banner, Item, MockContainer, Canvan };
