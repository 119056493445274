import React, { useEffect } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { sentryHub } from '@app/shared/utils/sentry';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';

export const SentryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId, environment: env, country, device, deviceOS } = usePublicEnv();

  useEffect(() => {
    try {
      sentryHub.init({ dsn: SENTRY_DSN, environment: env });
      sentryHub.setUser({ id: userId });
      sentryHub.setTag('country', country?.shortName);
      sentryHub.setTag('origin_env', 'frontend');
      sentryHub.setTag('device', device);
      sentryHub.setTag('deviceOS', deviceOS);
    } catch (error) {
      console.error('Error initializing sentry hub', (error as Error)?.message);
    }
  }, [env, userId, country?.shortName, device, deviceOS]);

  return children;
};
