import { DEVICESOS, DEVICES } from '@app/shared/config/device';

type Deeplink = {
  [key: string]: {
    ios: string;
    android: string;
    web?: string;
  };
};

export const DeepLink: Deeplink = {
  repeatOrder: {
    ios: 'pedidosya://repeatOrder?orderId={orderId}&partnerId={partnerId}&resetStack=true',
    android: 'pedidosya://myOrders/{orderId}',
    web: '/my-orders/repeat/order/{orderId}',
  },
  otherOrderQC: {
    ios: 'pedidosya://groceries-webview/ms-groceries-shop-list?bt={vertical}&origin=order-cancellation',
    android:
      'pedidosya://groceries-webview/ms-groceries-shop-list?bt={vertical}&origin=order-cancellation',
    web: '/restaurantes?city={city}',
  },
  otherOrder: {
    ios: 'pedidosya://verticals?bt={vertical}&origin=order-cancellation',
    android: 'pedidosya://verticals?bt={vertical}&origin=order-cancellation',
    web: '/restaurantes?city={city}',
  },
  home: {
    ios: 'pedidosya://launcher',
    android: 'pedidosya://launcher',
    web: '/',
  },
  orderStatus: {
    ios: 'pedidosya://orderStatus?orderId={id}',
    android: 'pedidosya://orderStatus?orderId={id}',
    web: '/order-state?code={code}&origin=myOrders&c={country}',
  },
  helpCenter: {
    ios: 'pedidosya://onlinehelp?order_id={id}&origin=order-cancellation&page_id=orderDetail',
    android: 'pedidosya://onlinehelp?order_id={id}&origin=order-cancellation&page_id=orderDetail',
    web: '/customer-service?orderId={id}&origin=order-cancellation&page_id=orderDetail',
  },
  helpCenterQA: {
    ios: 'pedidosya://onlinehelp',
    android: 'pedidosya://onlinehelp',
    web: '/customer-service',
  },
  addItems: {
    ios: 'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=order-cancellation&action=add-items',
    android:
      'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=order-cancellation&action=add-items',
  },
  changeAddress: {
    ios: 'pedidosya://orderactions/changeorderaddress?orderId={orderId}&flow=order-cancellation',
    android:
      'pedidosya://orderactions/changeorderaddress?orderId={orderId}&flow=order-cancellation',
  },
  coupons: {
    ios: 'pedidosya://vouchers-section',
    android: 'pedidosya://vouchers-section',
    web: '/vouchers-wallet',
  },
  wallet: {
    ios: 'pedidosya://wallet/home',
    android: 'pedidosya://wallet/home',
    web: '',
  },
  basketRecreation: {
    ios: 'pedidosya://basketRecreation?referenceId={orderId}&referenceType=ORDER&flow=order-cancellation&partnerId={partnerId}&country={country}',
    android:
      'pedidosya://basketRecreation?referenceId={orderId}&referenceType=ORDER&flow=order-cancellation&partnerId={partnerId}&country={country}',
    web: '/basket-recreation/{orderId}/orders?flow=order-cancellation',
  },
  detail: {
    ios: 'pedidosya://os/orderDetails?orderId={orderId}&origin=order-cancellation&hideNavigation=true',
    android:
      'pedidosya://os/orderDetails?orderId={orderId}&origin=order-cancellation&hideNavigation=true',
    web: '/order-details?orderId={orderId}&origin=order-cancellation',
  },
};

export const ACTIONS = {
  REPEAT_ORDER: 'repeatOrder',
  OTHER_ORDER: 'otherOrder',
  OTHER_ORDER_QC: 'otherOrderQC',
  HOME: 'home',
  ORDER_STATUS: 'orderStatus',
  HELP_CENTER: 'helpCenter',
  HELP_CENTER_QA: 'helpCenterQA',
  ADDITEMS: 'addItems',
  CHANGEADDRESS: 'changeAddress',
  COUPONS: 'coupons',
  WALLET: 'wallet',
  BASKET_RECREATION: 'basketRecreation',
  DETAIL: 'detail',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action: string, os: string, params: Object) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    case DEVICESOS.WEB:
      return replaceAll(DeepLink[action].web as string, params);
    default:
      return '';
  }

  function replaceAll(str: string, params: Object) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value as any);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device: string, deviceOS: string, action: string, params: Object) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  } else {
    return generateDeepLink(action, DEVICESOS.WEB, params);
  }
};
