import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { TrackEvents } from '@app/shared/config/trackEvents';
import { Constants } from '@app/shared/config/constants';
import { PubSubEvents } from '@app/cancellation/infra/config/pubSubEvents';
import {
  HelpEventsController,
  ControllerDependencies,
} from '@app/cancellation/domain/controllers/HelpEvents';

export const useHelpEvents: HelpEventsController = ({ dependencies }) => {
  const dpn = dependencies as ControllerDependencies;
  const { gtmTrack } = dpn.useTracker();
  const { redirect, emitEvent } = dpn.useLinkContext();
  const { state } = dpn.useCancellationContext();
  const isWebView = dpn.useIsWebView();

  dpn.useSub(PubSubEvents.ON_GO_HELP_CENTER, () => {
    if (state?.orderDetail?.orderId) {
      return redirect(ACTIONS.HELP_CENTER, { id: state?.orderDetail?.orderId });
    }

    redirect(ACTIONS.HELP_CENTER_QA, {});
  });

  dpn.useSub(PubSubEvents.ON_GO_BACK_HELP_CENTER, () => {
    const detail = state?.orderDetail;

    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE, {});
    }

    if (detail?.orderId) {
      return redirect(ACTIONS.ORDER_STATUS, {
        code: detail?.code,
        country: detail.countryCode,
      });
    }

    redirect(ACTIONS.HOME, {});
  });
};
