import React from 'react';
import isFunction from 'lodash/isFunction';
import { format } from '@pedidosya/currency-formatter';
import type { FC, PropsWithChildren } from 'react';

type Props = {
  amount: string | number;
  country: string;
} & PropsWithChildren;

export const NumberFormatter: FC<Props> = ({ amount, children, country }) => {
  const formatedPrice = format(parseFloat(amount as string), country);

  if (isFunction(children)) {
    return children(formatedPrice?.price);
  }

  return <>{formatedPrice?.price}</>;
};

export const Formatter = (amount: string | number, country: string): string => {
  return format(parseFloat(amount as string), country)?.price;
};
