import { ExternalUrls } from '@app/shared/config/externalUrls';
import Http from '@app/shared/utils/http/External';
import { ExternalRepository as IExternalRepository } from './externalRepository.type';
import { RedirectLoginResponse } from '@app/shared/models/RedirectLogin.response';

export class ExternalRepository implements IExternalRepository {
  constructor() {}

  public async getRedirectLogin(): Promise<RedirectLoginResponse> {
    const result = await Http.get(`${ExternalUrls.LOGIN}`);
    return result?.data;
  }
}
