/**
 * GlobalReasons
 *
 * @description Const with DH reasons of cancellation
 */
export enum GlobalReasons {
  CLOSED = 'CLOSED',
  ITEM_UNAVAILABLE = 'ITEM_UNAVAILABLE',
  NO_RESPONSE = 'NO_RESPONSE',
  TOO_BUSY = 'TOO_BUSY',
  UNREACHABLE = 'UNREACHABLE',
  OUTSIDE_DELIVERY_AREA = 'OUTSIDE_DELIVERY_AREA',
  MENU_ACCOUNT_SETTINGS = 'MENU_ACCOUNT_SETTINGS',
  WILL_NOT_WORK_WITH_PLATFORM = 'WILL_NOT_WORK_WITH_PLATFORM',
  BAD_LOCATION = 'BAD_LOCATION',
  NO_PICKER = 'NO_PICKER',
  MOV_NOT_REACHED = 'MOV_NOT_REACHED',
  BILLING_PROBLEM = 'BILLING_PROBLEM',
  NEVER_DELIVERED = 'NEVER_DELIVERED',
  DELIVERY_ETA_TOO_LONG = 'DELIVERY_ETA_TOO_LONG',
  COURIER_UNREACHABLE = 'COURIER_UNREACHABLE',
  NO_COURIER = 'NO_COURIER',
  COURIER_ACCIDENT = 'COURIER_ACCIDENT',
  REASON_UNKNOWN = 'REASON_UNKNOWN',
  WRONG_ORDER_ITEMS_DELIVERED = 'WRONG_ORDER_ITEMS_DELIVERED',
  FOOD_QUALITY_SPILLAGE = 'FOOD_QUALITY_SPILLAGE',
  CONTENT_WRONG_MISLEADING = 'CONTENT_WRONG_MISLEADING',
  CARD_READER_NOT_AVAILABLE = 'CARD_READER_NOT_AVAILABLE',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  MISTAKE_ERROR = 'MISTAKE_ERROR',
  BAD_WEATHER = 'BAD_WEATHER',
  BLACKLISTED = 'BLACKLISTED',
  UNPROFESSIONAL_BEHAVIOUR = 'UNPROFESSIONAL_BEHAVIOUR',
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  EXTRA_CHARGE_NEEDED = 'EXTRA_CHARGE_NEEDED',
  LATE_DELIVERY = 'LATE_DELIVERY',
  OUTSIDE_SERVICE_HOURS = 'OUTSIDE_SERVICE_HOURS',
  UNABLE_TO_FIND = 'UNABLE_TO_FIND',
  UNABLE_TO_PAY = 'UNABLE_TO_PAY',
  ORDER_MODIFICATION_NOT_POSSIBLE = 'ORDER_MODIFICATION_NOT_POSSIBLE',
  VOUCHER_NOT_APPLIED = 'VOUCHER_NOT_APPLIED',
}
