export enum Constants {
  FLOW_PARAM = 'flow',
  BANK_TRANSFER_DAYS = 7,
  CREDIT_DAYS = 7,
  VOID_DAYS = 7,
  COMPENSATION_IMAGE = 'https://images.dhmedia.io/image/pedidosya/order-management/voucher_fenix.jpg',
  RETENTION_IMAGE = 'https://images.dhmedia.io/image/pedidosya/fenix/illustrations/orderTrackingLightFinishingRestaurantsXSmall.svg',
  RETENTION_IMAGE_DELAY = 'https://images.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagEnabled2OpenSmallClock.svg',
  HELPCENTER_IMAGE = 'https://images.dhmedia.io/image/pedidosya/fenix/illustrations/addressChangeMedium.svg',
}
