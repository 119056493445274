import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { TrackerContext } from './context';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import { TrackerConfig } from '@shared/models/TrackerConfig';
import isEmpty from 'lodash/isEmpty';

type Props = {
  tracker?: {
    track(name: string, params: Object): void;
  };
  config?: TrackerConfig;
} & PropsWithChildren;

export const TrackerProvider: FC<Props> = memo(function TrackerProvider({
  children,
  tracker,
  config,
}) {
  const [trackerInstance, setTracker] = useState(tracker);

  const loadTracker = useCallback(async () => {
    try {
      await initializeTracker(config);
      setTracker({ track });
    } catch (e) {
      return null;
    }
  }, [config]);

  useEffect(() => {
    if (!isEmpty(trackerInstance)) {
      return;
    }

    loadTracker();
  }, [trackerInstance, loadTracker]);

  const value = useMemo(
    () => ({
      gtmTrack: trackerInstance?.track || null,
    }),
    [trackerInstance],
  );

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
});
